import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface FilterItem {
  label: string;
  value: string;
  selected: boolean;
  count?: number;
}

export interface FilterState {
  expanded: boolean;
  items?: FilterItem[];
  panelOpen: boolean;
}

export const useSelectedTagParams = () => {
  const [searchParams] = useSearchParams();
  return JSON.parse(searchParams.get('tags') || '[]') as string[];
};

export const useFilters = (initialTags: readonly string[] | undefined) => {
  const tagParams = useSelectedTagParams();

  const initState = useCallback(() => {
    return {
      expanded: true,
      items: initialTags?.map((tag) => ({
        label: tag,
        value: tag,
        selected: tagParams.includes(tag),
      })),
      panelOpen: tagParams.length > 0,
    };
  }, [initialTags, tagParams]);

  const [filter, setFilter] = useState<FilterState>(initState());

  useEffect(() => {
    if (filter.items?.length === initialTags?.length) return;
    setFilter(() => initState());
  }, [filter.items?.length, initState, initialTags?.length]);

  const [_, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (items: FilterItem[]) => {
      setSearchParams((params) => {
        const selected = items.filter((x) => x.selected);
        if (selected.length > 0) {
          params.set('tags', JSON.stringify(selected.map((x) => x.value)));
        } else {
          params.delete('tags');
        }
        params.set('page', '1'); // reset pagination when filters change
        return params;
      });
    },
    [setSearchParams],
  );

  const clearFilter = useCallback(() => {
    setFilter((current) => ({
      ...current,
      expanded: true,
      items: current.items?.map((item) => ({ ...item, selected: false })),
      panelOpen: false,
    }));
    setSearchParams((params) => {
      params.delete('tags');
      params.set('page', '1');
      return params;
    });
  }, [setSearchParams]);

  const setFilterItems = useCallback(
    (newItems: FilterItem[]) => {
      setFilter((state) => ({ ...state, items: newItems }));
      updateSearchParams(newItems);
    },
    [updateSearchParams],
  );

  const toggleAccordion = useCallback(
    () => setFilter((x) => ({ ...x, expanded: !x.expanded })),
    [],
  );

  const toggleItem = useCallback(
    (value: string) => {
      const newItems = filter.items?.map((item) =>
        item.value === value ? { ...item, selected: !item.selected } : item,
      );
      setFilter((prev) => ({ ...prev, items: newItems }));
      updateSearchParams(newItems || []);
    },
    [filter.items, updateSearchParams],
  );

  const togglePanel = useCallback(() => {
    setFilter((current) => ({ ...current, panelOpen: !current.panelOpen }));
  }, []);

  const selectedTags = filter.items?.filter((item) => item.selected).map((item) => item.value);

  return {
    filter,
    clearFilter,
    selectedTags,
    setFilterItems,
    toggleAccordion,
    toggleItem,
    togglePanel,
  };
};

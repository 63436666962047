import type Konva from 'konva';
import type { IRect } from 'konva/lib/types';
import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import type { LinkName, SettingsTabName, StudioTransformerState } from './layout/utils';

export type Direction = 'back' | 'backward' | 'forward' | 'front';
export type Position = 'bottom' | 'center' | 'left' | 'middle' | 'right' | 'top';
export type LetterCase = 'capitalize' | 'lowercase' | 'uppercase';

export interface BaseStudioItem {
  id: string;
  x: number;
  y: number;
}

export interface StudioCircle extends Konva.CircleConfig {
  type: 'circle';
}

export interface StudioRect extends Konva.RectConfig {
  type: 'rect';
}

export interface StudioTriangle extends Konva.RegularPolygonConfig {
  type: 'triangle';
}

// ideally this would extend Omit<Konva.ImageConfig, 'image'>
// but it doesn't like that for some reason
export interface StudioMedia extends Konva.ShapeConfig {
  __mediaItemId: number;
  src: string;
  // these are the other two properties from Konva.ImageConfig
  crop?: IRect;
  cornerRadius?: number | number[];
}

export interface StudioImage extends StudioMedia {
  type: 'image';
}

export interface StudioVideo extends StudioMedia {
  type: 'video';
}

export interface StudioText extends Konva.TextConfig {
  __dataItemId?: number;
  __dataItemKey?: string;
  __fontId?: number;
  __textTransform?: LetterCase;
  type: 'text';
}

export type StudioItem = (
  | StudioCircle
  | StudioRect
  | StudioTriangle
  | StudioImage
  | StudioVideo
  | StudioText
) &
  BaseStudioItem;

export interface StudioFontFamily {
  options?: {
    style: 'normal' | 'italic';
    weight: 'normal' | 'bold';
  };
  uri: string;
}

export interface StudioFont {
  __fontId?: number;
  family?: StudioFontFamily[];
  key: string;
  value: string;
}

export interface StudioContextType {
  activeSettingsTab: SettingsTabName;
  activeSidebarItem: LinkName;
  addItems: (items: StudioItem[]) => void;
  alignItem: (itemId: string, position: Position) => void;
  canRedo: boolean;
  canUndo: boolean;
  copiedItems: StudioItem[];
  copyItems: (ids: string[]) => void;
  dataItemCellSelectOpen: boolean;
  deleteItems: (ids: string[]) => void;
  dirty: boolean;
  duplicateItems: (items: StudioItem[]) => void;
  editItem: (id?: string) => void;
  editingItem?: StudioItem;
  fonts?: StudioFont[];
  items: StudioItem[];
  loadItems: (items: StudioItem[]) => void;
  loaded: boolean;
  moveItem: (itemId: string, direction: Direction) => void;
  redo: () => void;
  reorderItems: (ids: string[]) => void;
  resetDirty: () => void;
  selectItems: (ids: string[]) => void;
  selectedItems: StudioItem[];
  setActiveSettingsTab: (tab: SettingsTabName) => void;
  setDataItemCellSelectOpen: (open: boolean) => void;
  setFonts: (fonts: StudioFont[]) => void;
  setMessage: (message?: string) => void;
  setSidebarItem: (name: LinkName) => void;
  setStage: (stage: Konva.Stage) => void;
  setTransformer: (transformer: Partial<StudioTransformerState>) => void;
  stage?: Konva.Stage;
  transformer: StudioTransformerState;
  undo: () => void;
  updateItems: (
    items: Array<Partial<Omit<StudioItem, 'type'>> & Required<Pick<StudioItem, 'id'>>>,
  ) => void;
}

export const StudioContext = createContext<StudioContextType | undefined>(undefined);

export const useStudio = () => {
  const context = useContext(StudioContext);
  assert(context !== undefined, 'useStudio must be used within a StudioProvider');
  return context;
};

export interface StudioProviderProps extends StudioContextType {
  children: ReactNode;
}

export const StudioProvider = ({ children, ...rest }: StudioProviderProps) => (
  <StudioContext.Provider value={{ ...rest }}>{children}</StudioContext.Provider>
);

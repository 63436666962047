import { Box, Button, Link, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';

export interface ReportDownloadDialogProps {
  close: () => void;
  from: DateTime | null;
  to: DateTime | null;
  reportUrl: string | null;
}

export const ReportDownloadDialog = ({ close, from, to, reportUrl }: ReportDownloadDialogProps) => {
  return (
    <Dialog fullWidth maxWidth="md" onClose={close} open={Boolean(reportUrl)}>
      <DialogTitle onClose={close}>Playout Report</DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          You have successfully generated the playout report from{' '}
          <b>{from?.toLocaleString(DateTime.DATE_FULL)}</b> to{' '}
          <b>{to?.toLocaleString(DateTime.DATE_FULL)}</b>.
        </DialogContentTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle1">Click this link to download the report:</Typography>
          {reportUrl && (
            <Link sx={{ fontSize: '1.2em' }} target="_blank" rel="noreferrer" href={reportUrl}>
              Playout Report
            </Link>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Box, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { bool, mixed, number, object, string } from 'yup';
import { useAppContext } from '~/contexts';
import { DeviceNightlyBehavior } from '~/generated/graphql';
import { capitalize } from '~/lib/string';
import { DialogForm } from '../../../../components/dialogs/components';
import type {
  DeviceList__Channel as Channel,
  DeviceList__DeviceGroup as DeviceGroup,
  DeviceList__TvBrand as TVBrand,
} from '../../queries/list.generated';
import { SCREENSHOT_INTERVALS } from '../lib/helpers';

export interface BulkDeviceSettingsFormValues {
  defaultChannelId: number | null | undefined;
  demo: boolean | undefined;
  deviceGroupId: number | null | undefined;
  eventStream: boolean | undefined;
  internal: boolean | undefined;
  nightlyBehavior: DeviceNightlyBehavior | undefined;
  overscan: number | undefined;
  realtime: boolean | undefined;
  screenshotInterval: string | null | undefined;
  silence: boolean | undefined;
  tvBrandId: number | null | undefined;
}

export const bulkDeviceSettingsValidationSchema = object({
  defaultChannelId: number().integer().min(1).notRequired(),
  demo: bool().optional(),
  deviceGroupId: number().notRequired(),
  eventStream: bool().optional(),
  internal: bool().optional(),
  nightlyBehavior: mixed<DeviceNightlyBehavior>()
    .oneOf(Object.values(DeviceNightlyBehavior) as DeviceNightlyBehavior[])
    .optional(),
  overscan: number().min(50).max(110).optional(),
  realtime: bool().optional(),
  screenshotInterval: string().oneOf(Object.keys(SCREENSHOT_INTERVALS)).notRequired(),
  silence: bool().optional(),
  tvBrandId: number().notRequired(),
});

export const useBulkDeviceSettingsFormik = (
  initialValues: BulkDeviceSettingsFormValues,
  onSubmit: (values: BulkDeviceSettingsFormValues) => void,
) => {
  return useFormik<BulkDeviceSettingsFormValues>({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: bulkDeviceSettingsValidationSchema,
  });
};

interface BulkDeviceSettingsFormProps {
  formik: ReturnType<typeof useBulkDeviceSettingsFormik>;
  channels: ReadonlyArray<Channel>;
  deviceGroups: ReadonlyArray<DeviceGroup>;
  tvBrands: ReadonlyArray<TVBrand>;
}

export const BulkDeviceSettingsForm = ({
  formik,
  deviceGroups,
  channels,
  tvBrands,
  ...props
}: BulkDeviceSettingsFormProps) => {
  const { currentNetwork } = useAppContext();
  const { currentUser } = useAppContext();

  const screenshotIntervalOptions = [
    { label: 'No Change', value: '' },
    { label: 'Disable', value: 'none' },
    { label: '1 minute', value: 'PT1M' },
    { label: '2 minutes', value: 'PT2M' },
    { label: '5 minutes', value: 'PT5M' },
    { label: '10 minutes', value: 'PT10M' },
    { label: '15 minutes', value: 'PT15M' },
    { label: '30 minutes', value: 'PT30M' },
    { label: '60 minutes', value: 'PT60M' },
  ];

  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className="label">Automatic Screenshots</span>
        <Select
          name="screenshotInterval"
          disabled={formik.isSubmitting}
          value={
            formik.values.screenshotInterval === undefined
              ? ''
              : formik.values.screenshotInterval === null
              ? 'none'
              : formik.values.screenshotInterval
          }
          onChange={(event) => {
            const value = event.target.value;
            void formik.setFieldValue(
              'screenshotInterval',
              value === '' ? undefined : value === 'none' ? null : value,
            );
          }}
          fullWidth
        >
          {screenshotIntervalOptions.map((option) => (
            <MenuItem key={option.value || 'no-change'} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </label>

      {currentNetwork.canManage.value && (
        <label>
          <span className="label">Default Channel</span>
          <Select
            name="defaultChannelId"
            disabled={formik.isSubmitting || channels.length === 0}
            value={
              formik.values.defaultChannelId === undefined
                ? ''
                : formik.values.defaultChannelId === null
                ? 'none'
                : formik.values.defaultChannelId
            }
            onChange={(event) => {
              const value = event.target.value;
              void formik.setFieldValue(
                'defaultChannelId',
                value === '' ? undefined : value === 'none' ? null : Number(value),
              );
            }}
            fullWidth
          >
            <MenuItem value="">No Change</MenuItem>
            <MenuItem value="none">None</MenuItem>
            {channels.map(({ id, name, number, thumbnailFile }) => (
              <MenuItem key={id} value={id}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& > img': {
                      mr: 2,
                      flexShrink: 0,
                      background: 'none !important',
                      boxShadow: 'none !important',
                    },
                  }}
                >
                  {thumbnailFile.uri && (
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={thumbnailFile.uri}
                      src={thumbnailFile.uri}
                      alt=""
                    />
                  )}
                  {name}
                  {number ? ` (${number})` : ''}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </label>
      )}

      {currentUser.admin && (
        <label>
          <span className="label">Demo</span>
          <Select
            name="demo"
            disabled={formik.isSubmitting}
            value={typeof formik.values.demo === 'boolean' ? String(formik.values.demo) : ''}
            onChange={(event) => {
              const value = event.target.value;
              void formik.setFieldValue('demo', value === '' ? undefined : value === 'true');
            }}
            fullWidth
          >
            <MenuItem value="">No Change</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </label>
      )}

      {currentNetwork.canManage.value && (
        <>
          <label>
            <span className="label">Device Group</span>
            <Select
              name="deviceGroupId"
              disabled={formik.isSubmitting}
              value={
                formik.values.deviceGroupId === undefined
                  ? ''
                  : formik.values.deviceGroupId === null
                  ? 'none'
                  : String(formik.values.deviceGroupId)
              }
              onChange={(event) => {
                const value = event.target.value;
                void formik.setFieldValue(
                  'deviceGroupId',
                  value === '' ? undefined : value === 'none' ? null : Number(value),
                );
              }}
              fullWidth
            >
              <MenuItem value="">No Change</MenuItem>
              <MenuItem value="none">None</MenuItem>
              {deviceGroups.map(({ id, name }) => (
                <MenuItem key={id} value={String(id)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </label>

          <label>
            <span className="label">Event Stream</span>
            <Select
              name="eventStream"
              disabled={formik.isSubmitting}
              value={
                formik.values.eventStream !== undefined ? String(formik.values.eventStream) : ''
              }
              onChange={(event) => {
                const value = event.target.value;
                void formik.setFieldValue(
                  'eventStream',
                  value === '' ? undefined : value === 'true',
                );
              }}
              fullWidth
            >
              <MenuItem value="">No Change</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </label>
        </>
      )}
      {currentUser.admin && (
        <label>
          <span className="label">Internal</span>
          <Select
            name="internal"
            disabled={formik.isSubmitting}
            value={formik.values.internal !== undefined ? String(formik.values.internal) : ''}
            onChange={(event) => {
              const value = event.target.value;
              void formik.setFieldValue('internal', value === '' ? undefined : value === 'true');
            }}
            fullWidth
          >
            <MenuItem value="">No Change</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </label>
      )}

      {currentNetwork.canManage.value && (
        <label>
          <span className="label">Nightly Reboot/Restart</span>
          <Select
            name="nightlyBehavior"
            disabled={formik.isSubmitting}
            value={formik.values.nightlyBehavior === undefined ? '' : formik.values.nightlyBehavior}
            onChange={(event) => {
              const value = event.target.value;
              void formik.setFieldValue('nightlyBehavior', value === '' ? undefined : value);
            }}
            fullWidth
          >
            <MenuItem value="">No Change</MenuItem>
            {Object.values(DeviceNightlyBehavior)
              .filter((value) => currentUser.admin || value !== DeviceNightlyBehavior.None)
              .map((value) => (
                <MenuItem key={value} value={value}>
                  {capitalize(value)}
                </MenuItem>
              ))}
          </Select>
        </label>
      )}

      <label>
        <span className="label">Overscan</span>
        <TextField
          helperText="Overscan for device, between 50 and 110. Example: 87 for 87%, 100 for fullscreen (no scaling)"
          name="overscan"
          disabled={formik.isSubmitting}
          type="number"
          value={formik.values.overscan ?? ''}
          onChange={(event) => {
            const value = event.target.value;
            void formik.setFieldValue('overscan', value === '' ? undefined : Number(value));
          }}
          InputProps={{ inputProps: { min: 50, max: 110 } }}
          fullWidth
        />
      </label>

      {currentNetwork.canManage.value && (
        <>
          <label>
            <span className="label">Realtime</span>
            <Select
              name="realtime"
              disabled={formik.isSubmitting}
              value={formik.values.realtime !== undefined ? String(formik.values.realtime) : ''}
              onChange={(event) => {
                const value = event.target.value;
                void formik.setFieldValue('realtime', value === '' ? undefined : value === 'true');
              }}
              fullWidth
            >
              <MenuItem value="">No Change</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </label>

          <label>
            <span className="label">Silence</span>
            <Select
              name="silence"
              disabled={formik.isSubmitting}
              value={formik.values.silence !== undefined ? String(formik.values.silence) : ''}
              onChange={(event) => {
                const value = event.target.value;
                void formik.setFieldValue('silence', value === '' ? undefined : value === 'true');
              }}
              fullWidth
            >
              <MenuItem value="">No Change</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </label>

          <label>
            <span className="label">TV Brand</span>
            <Select
              name="tvBrandId"
              disabled={formik.isSubmitting}
              value={
                formik.values.tvBrandId === undefined
                  ? ''
                  : formik.values.tvBrandId === null
                  ? 'none'
                  : String(formik.values.tvBrandId)
              }
              onChange={(event) => {
                const value = event.target.value;
                void formik.setFieldValue(
                  'tvBrandId',
                  value === '' ? undefined : value === 'none' ? null : Number(value),
                );
              }}
              fullWidth
            >
              <MenuItem value="">No Change</MenuItem>
              <MenuItem value="none">None</MenuItem>
              {tvBrands.map(({ id, name }) => (
                <MenuItem key={id} value={String(id)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </label>
        </>
      )}
    </DialogForm>
  );
};

import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ImpersonationButton } from '~/components/ImpersonationButton';
import { UploadDialog } from '~/components/UploadDialog';
import { UploadSnackbar } from '~/components/UploadSnackbar';
import { LoadingPane } from '~/components/loading-pane';
import { AppContextProvider, useFetchAppContext } from '~/contexts/app';
import { UploadProvider } from '~/contexts/upload';
import { isSmDown } from '~/lib/responsive';
import { SideNav } from './components';
import { SideNavProvider } from './context';

export const MainLayout = () => {
  const { currentNetwork, currentUser, impersonating, trueUser } = useFetchAppContext();
  const location = useLocation();

  const isDeviceSmDown = isSmDown();

  const hideNav = useMemo(() => {
    const { pathname } = location;
    return (
      /^\/networks\/\d+\/studio\/\d+$/.test(pathname) ||
      /^\/networks\/\d+\/content\/\d+\/(apps|media)\/\d+\/(details|settings|thumbnails|renditions|audit-logs).*$/.test(
        pathname,
      ) ||
      /^\/networks\/\d+\/templates\/\d+\/create$/.test(pathname)
    );
  }, [location]);

  useEffect(() => {
    if (!currentUser || !trueUser) return;

    Sentry.setUser({ email: trueUser.email });
    Sentry.setContext(
      'impersonated_user',
      currentUser.id === trueUser.id ? null : { email: currentUser.email },
    );
  }, [currentUser, trueUser]);

  if (!currentUser || !currentNetwork || !trueUser) {
    return <LoadingPane in />;
  }

  return (
    <AppContextProvider
      currentUser={currentUser}
      currentNetwork={currentNetwork}
      impersonating={impersonating ?? false}
      trueUser={trueUser}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UploadProvider>
          <ImpersonationButton />
          <Box
            sx={{
              display: 'flex',
              height: '100%',
            }}
          >
            <SideNavProvider initialOpen={!isDeviceSmDown}>
              {!hideNav && <SideNav />}
              <Box
                component="main"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  overflow: 'hidden',
                }}
              >
                <Outlet />
              </Box>
            </SideNavProvider>
          </Box>
          <UploadDialog />
          <UploadSnackbar />
        </UploadProvider>
      </LocalizationProvider>
    </AppContextProvider>
  );
};

import { styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export const DataSourceActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const MetaItem = styled('div')(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(0, 3),
  borderRight: '1px solid #e6e6e6',
  '& img': {
    height: '25px',
  },
  '&.first': {
    paddingLeft: '0px',
  },
  '&.last': {
    borderWidth: '0px',
  },
}));

export const SpreadsheetyDataGrid = styled(DataGrid)(() => ({
  '& [data-field="id"]': {
    backgroundColor: '#f5f5f5',
    color: '#969696',
    borderRight: '1px solid #e6e6e6',
  },
  '& .disabled': {
    backgroundColor: '#f5f5f5',
  },
  '& .MuiDataGrid-scrollbar--vertical': {
    display: 'none',
  },
})) as typeof DataGrid;

import { ImageAspectRatio, Link as MuiLink } from '@mui/icons-material';
import {
  DetailAccordion,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import { DetailSection } from '~/components/DetailSection';
import { Link } from '~/components/link';
import { mediaDimensions, mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import type { MediaItemOriginalProps__MediaItem } from './MediaItemOriginal.generated';

/* GraphQL */ `#graphql
fragment MediaItemOriginalProps__MediaItem on MediaItem {
  id
  kind
  originalFile {
    id
    originalFilename
    uri
  }
  originalMetadata
}
`;

export interface MediaItemOriginalProps {
  mediaItem: MediaItemOriginalProps__MediaItem;
}

export const MediaItemOriginal = ({ mediaItem }: MediaItemOriginalProps) => (
  <DetailAccordion defaultExpanded>
    <DetailAccordionSummary>Original</DetailAccordionSummary>
    <DetailAccordionDetails>
      <DetailSection
        icon={<MuiLink />}
        title="File"
        value={
          <Link to={mediaItem.originalFile.uri} target="_blank">
            {mediaItem.originalFile.originalFilename}
          </Link>
        }
      />
      <DetailSection icon="Kb" title="Size" value={formatBytes(mediaSize(mediaItem))} />
      <DetailSection
        icon={<ImageAspectRatio />}
        title="Dimensions"
        value={mediaDimensions(mediaItem).join(' x ')}
      />
    </DetailAccordionDetails>
  </DetailAccordion>
);

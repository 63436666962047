import { Sell } from '@mui/icons-material';
import {
  FilterAccordionContainer,
  FilterCollapsibleList,
  FilterContainer,
  FilterFooter,
  FilterHeader,
} from '~/components/Filter';
import type { PlaylistList__FilterCount } from '../../queries/queries.generated';
import type { FilterItem, FilterState } from '../lib';

interface FilterAccordionProps {
  clearFilter: () => void;
  filter: FilterState;
  filterCounts?: PlaylistList__FilterCount;
  toggleAccordion: () => void;
  updateFilter: (newItems: FilterItem[]) => void;
}

export const FilterAccordion = ({
  clearFilter,
  filter,
  filterCounts,
  toggleAccordion,
  updateFilter,
}: FilterAccordionProps) => {
  const items = filter.items || [];
  return (
    <FilterContainer>
      <FilterHeader />
      <FilterAccordionContainer>
        {items.length > 0 && (
          <FilterCollapsibleList
            Icon={Sell}
            expanded={filter.expanded}
            items={items.map((item) => {
              const matched = filterCounts?.tags.find((c) => c.filter === item.value);
              return { ...item, count: matched?.count || 0 };
            })}
            label="Tags"
            loading={!filterCounts}
            onClick={(_, newItems) => updateFilter(newItems)}
            onExpand={toggleAccordion}
          />
        )}
      </FilterAccordionContainer>
      <FilterFooter handleClear={clearFilter} />
    </FilterContainer>
  );
};

import { MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { array, bool, number, object, string } from 'yup';
import { useUpdateDevice } from '~/api/devices';
import { useAssignPropertyGroup, useRemovePropertyGroup } from '~/api/property-groups';
import { ChannelAutocomplete } from '~/components/channels';
import { DetailSelect, DetailSwitch, DetailTextField } from '~/components/forms/details';
import { DurationSlider } from '~/components/inputs';
import type { FormTableData } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { DeviceNightlyBehavior, PropertyOwnerType } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { titleize } from '~/lib/string';
import { duration } from '~/lib/validators';
import { AddRemoteTargets } from '../components';
import { useDevice } from '../context';

const fcPropertyGroups = [{ id: -1, name: 'Remote Targets' }];

interface PropertyGroupFormData {
  id: number;
  name: string;
  propertyGroupId: number;
  fields: FormTableData[];
}

const validationSchema = object({
  defaultChannelId: number().integer().min(1).nullable().defined(),
  demo: bool().required(),
  deviceGroupId: number().nullable(),
  eventStream: bool().required(),
  internal: bool().required(),
  nightlyBehavior: string().oneOf(['NONE', 'REBOOT', 'RESTART']).required(),
  overscan: number().required().min(50).max(110),
  propertyValuesAttributes: array(object({ id: number().required(), value: string().nullable() })),
  realtime: bool().required(),
  remoteTargets: array(number()),
  screenshotInterval: duration({ min: { minutes: 1 }, max: { hours: 1 } }),
  silence: bool().required(),
  timeZone: string().required(),
  tvBrandId: number().nullable(),
});

export const useSettingsForm = () => {
  const { channels, device, deviceGroups, propertyGroups, timeZones, tvBrands } = useDevice();
  const [updateDevice] = useUpdateDevice();
  const [assignPropertyGroup] = useAssignPropertyGroup();
  const [removePropertyGroup] = useRemovePropertyGroup();
  const [confirm, confirmDialogProps] = useConfirmDialog();
  const [showAddPropertyDialog, setShowAddPropertyDialog] = useState(false);
  const { currentUser } = useAppContext();
  const { currentNetwork } = useAppContext();

  const initialValues = useMemo(
    () => ({
      defaultChannelId: device.defaultChannel?.id ?? null,
      demo: device.demo,
      deviceGroupId: device.group?.id || -1,
      eventStream: device.eventStream,
      internal: device.internal,
      nightlyBehavior: device.nightlyBehavior,
      overscan: device.overscan || undefined,
      propertyValuesAttributes: device.propertyGroups
        .flatMap((x) => x.propertyValues)
        .map(({ id, propertyDefinition, value }) => ({
          id,
          value: value || propertyDefinition.defaultValue,
        })),
      realtime: device.realtime,
      remoteTargetIds:
        device.remoteTargets.length > 0 ? device.remoteTargets.map((x) => x.id) : undefined,
      screenshotInterval: device.screenshotInterval ?? '',
      silence: device.silence,
      timeZone: device.timeZone,
      tvBrandId: device.tvBrand?.id || -1,
    }),
    [device],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await updateDevice({
        variables: {
          deviceId: device.id,
          patch: {
            ...newValues,
            deviceGroupId: newValues.deviceGroupId === -1 ? null : newValues.deviceGroupId,
            screenshotInterval: newValues.screenshotInterval || null,
            tvBrandId: newValues.tvBrandId === -1 ? null : newValues.tvBrandId,
          },
        },
      });
    },
    validateOnMount: true,
    validationSchema,
  });

  const generalSettings: FormTableData[] = useMemo(() => {
    const channel = channels.find(({ id }) => id === formik.values.defaultChannelId) ?? null;

    const settings = [
      {
        heading: 'Group',
        subHeading: 'The group this device belongs to',
        dataField: (
          <DetailSelect
            aria-label="group"
            disabled={formik.isSubmitting || !currentNetwork.canManage.value}
            name="deviceGroupId"
            value={formik.values.deviceGroupId}
            onChange={formik.handleChange}
          >
            <MenuItem value={-1}>None</MenuItem>
            {deviceGroups.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Time Zone',
        subHeading: 'Time zone where this device is located',
        dataField: (
          <DetailSelect
            aria-label="time zone"
            disabled={formik.isSubmitting || !currentUser.admin}
            name="timeZone"
            value={formik.values.timeZone}
            onChange={formik.handleChange}
          >
            {timeZones.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Default Channel',
        subHeading: 'Initial channel that is automatically tuned for this device',
        dataField: (
          <ChannelAutocomplete
            disabled={channels.length === 0 || !currentNetwork.canManage.value}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            onChange={(_event, channel) =>
              formik.setFieldValue('defaultChannelId', channel?.id ?? null)
            }
            options={channels}
            value={channel}
          />
        ),
      },
      {
        heading: 'Automatic Screenshots',
        subHeading: 'Turn on/off automatic screenshots and set interval',
        dataField: (
          <DurationSlider
            max={{ hours: 1 }}
            min={{ minutes: 1 }}
            name="screenshotInterval"
            step={{ minutes: 1 }}
            value={formik.values.screenshotInterval}
            onChange={formik.handleChange}
          />
        ),
      },
      {
        heading: 'Overscan',
        subHeading:
          'Overscan for device, between 50 and 110.  Example: 87 for 87%, 100 for fullscreen (no scaling)',
        dataField: (
          <DetailTextField
            aria-label="overscan"
            disabled={formik.isSubmitting}
            name="overscan"
            value={formik.values.overscan}
            onChange={formik.handleChange}
            type="number"
          />
        ),
      },
      {
        heading: 'Nightly Reboot/Restart',
        subHeading: 'Set or disable the nightly reboot/restart',
        dataField: (
          <DetailSelect
            aria-label="nightly behavior"
            disabled={
              formik.isSubmitting ||
              !currentNetwork.canManage.value ||
              (!currentUser.admin && formik.values.nightlyBehavior === DeviceNightlyBehavior.None)
            }
            name="nightlyBehavior"
            value={formik.values.nightlyBehavior}
            onChange={formik.handleChange}
          >
            {Object.values(DeviceNightlyBehavior)
              .filter(
                (value) =>
                  currentUser.admin ||
                  value !== DeviceNightlyBehavior.None ||
                  formik.values.nightlyBehavior === DeviceNightlyBehavior.None,
              )
              .map((value) => (
                <MenuItem key={value} value={value}>
                  {titleize(value)}
                </MenuItem>
              ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'TV Brand',
        subHeading: 'The TV brand for the device',
        dataField: (
          <DetailSelect
            aria-label="tv brand"
            disabled={formik.isSubmitting || !currentNetwork.canManage.value}
            name="tvBrandId"
            value={formik.values.tvBrandId}
            onChange={formik.handleChange}
          >
            <MenuItem value={-1}>None</MenuItem>
            {tvBrands.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
    ];
    if (currentNetwork.canManage.value) {
      settings.push(
        {
          heading: 'Event Stream',
          subHeading: 'Turn on/off event log streaming',
          dataField: (
            <DetailSwitch
              checked={formik.values.eventStream}
              color="primary"
              disabled={formik.isSubmitting}
              name="eventStream"
              onChange={formik.handleChange}
            />
          ),
        },
        {
          heading: 'Real Time',
          subHeading: 'Turn on/off real time mode',
          dataField: (
            <DetailSwitch
              checked={formik.values.realtime}
              color="primary"
              disabled={formik.isSubmitting}
              name="realtime"
              onChange={formik.handleChange}
            />
          ),
        },
        {
          heading: 'Silence',
          subHeading: 'Hide this device from the dashboard',
          dataField: (
            <DetailSwitch
              checked={formik.values.silence}
              color="primary"
              disabled={formik.isSubmitting}
              name="silence"
              onChange={formik.handleChange}
            />
          ),
        },
      );
    }

    if (currentUser.admin) {
      settings.push(
        {
          heading: 'Internal',
          subHeading: 'Turn on/off Internal mode',
          dataField: (
            <DetailSwitch
              checked={formik.values.internal}
              color="primary"
              disabled={formik.isSubmitting || !currentUser.admin}
              name="internal"
              onChange={formik.handleChange}
            />
          ),
        },
        {
          heading: 'Demo',
          subHeading: 'Turn on/off Demo mode',
          dataField: (
            <DetailSwitch
              checked={formik.values.demo}
              color="primary"
              disabled={formik.isSubmitting || !currentUser.admin}
              name="demo"
              onChange={formik.handleChange}
            />
          ),
        },
      );
    }

    return settings;
  }, [
    channels,
    formik,
    deviceGroups,
    currentUser.admin,
    currentNetwork.canManage,
    timeZones,
    tvBrands,
  ]);

  const getPropertyGroupValue = useCallback(
    (id: number) => formik.values.propertyValuesAttributes.find((x) => x.id === id)?.value,
    [formik],
  );

  const setPropertyGroupValue = useCallback(
    (id: number, value: string) => {
      const index = formik.values.propertyValuesAttributes.findIndex((x) => x.id === id);
      void formik.setFieldValue(`propertyValuesAttributes.${index}`, {
        id,
        value: String(value),
      });
    },
    [formik],
  );

  const propertyGroupSettings: PropertyGroupFormData[] = useMemo(
    () =>
      device.propertyGroups.map((pg) => ({
        id: pg.id,
        name: pg.name,
        propertyGroupId: pg.propertyGroupId,
        fields:
          pg.propertyValues.length === 0
            ? [{ heading: `No ${pg.name} settings` }]
            : pg.propertyValues.map(({ id, propertyDefinition }) => ({
                heading: propertyDefinition.label,
                subHeading: propertyDefinition.helpText,
                dataField:
                  propertyDefinition.kind === 'boolean' ? (
                    <DetailSwitch
                      checked={getPropertyGroupValue(id) === 'true'}
                      color="primary"
                      disabled={formik.isSubmitting}
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.checked))}
                    />
                  ) : propertyDefinition.allowedValues != null ? (
                    <DetailSelect
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.value))}
                      value={getPropertyGroupValue(id)}
                    >
                      <MenuItem value="">(None)</MenuItem>
                      {propertyDefinition.allowedValues.map(({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </DetailSelect>
                  ) : (
                    <DetailTextField
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.value))}
                      value={getPropertyGroupValue(id)}
                    />
                  ),
              })),
      })),
    [device, formik, getPropertyGroupValue, setPropertyGroupValue],
  );

  const addPropertyGroup = useCallback(
    async ({ id }: { id: string }) => {
      const propertyGroupId = Number(id);

      // handle remote targets situation
      if (propertyGroupId === -1) {
        await formik.setFieldValue('remoteTargetIds', [], true);
        setShowAddPropertyDialog(false);
        return;
      }

      const result = await assignPropertyGroup({
        variables: {
          ownerId: device.id,
          ownerType: PropertyOwnerType.Device,
          propertyGroupId,
        },
      });
      if (!result.errors?.length) {
        setShowAddPropertyDialog(false);
      }
    },
    [assignPropertyGroup, device.id, formik],
  );

  const deletePropertyGroup = useCallback(
    async (propertyGroup: { propertyGroupId: number }) => {
      if (!(await confirm())) return;
      await removePropertyGroup({
        variables: {
          ownerId: device.id,
          ownerType: PropertyOwnerType.Device,
          propertyGroupId: propertyGroup.propertyGroupId,
        },
      });
    },
    [confirm, device, removePropertyGroup],
  );

  const remoteTargetSettings: FormTableData[] = useMemo(
    () => [
      {
        heading: 'Remote Targets',
        subHeading: 'Remote devices to control using the EPG',
        dataField: (
          <AddRemoteTargets
            currentDeviceId={device.id}
            onChange={(ids) => formik.setFieldValue('remoteTargetIds', ids, true)}
            values={formik.values.remoteTargetIds ?? []}
          />
        ),
      },
    ],
    [device.id, formik],
  );

  const filteredPropertyGroups = useMemo(
    () => [
      ...(!formik.values.remoteTargetIds ? fcPropertyGroups : []),
      ...propertyGroups.filter(
        ({ id }) =>
          !device.propertyGroups.flatMap(({ propertyGroupId }) => propertyGroupId).includes(id),
      ),
    ],
    [device.propertyGroups, formik.values.remoteTargetIds, propertyGroups],
  );

  return {
    addPropertyGroup,
    confirmDialogProps,
    deletePropertyGroup,
    filteredPropertyGroups,
    formik,
    generalSettings,
    propertyGroupSettings,
    remoteTargetSettings,
    setShowAddPropertyDialog,
    showAddPropertyDialog,
  };
};

import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MediaItemThumbnailsVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  networkId: Types.Scalars['Int']['input'];
}>;


export type MediaItemThumbnails = { readonly __typename: 'Query', readonly network?: { readonly __typename: 'Network', readonly id: number, readonly mediaItem?: { readonly __typename: 'MediaItem', readonly id: number, readonly thumbnails: ReadonlyArray<{ readonly __typename: 'Thumbnail', readonly boundsHeight: number, readonly boundsWidth: number, readonly uri: string }> } | null } | null };


export const MediaItemThumbnailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MediaItemThumbnails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"network"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"mediaItem"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaItemThumbnailsProps__MediaItem"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MediaItemThumbnailsProps__MediaItem"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MediaItem"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"boundsHeight"}},{"kind":"Field","name":{"kind":"Name","value":"boundsWidth"}},{"kind":"Field","name":{"kind":"Name","value":"uri"}}]}}]}}]} as unknown as DocumentNode<MediaItemThumbnails, MediaItemThumbnailsVariables>;
import { Box, styled, useTheme } from '@mui/material';
import type { ComponentProps, ReactChildren, ReactNode } from 'react';

export const ListActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
}));

export const ListPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  minHeight: 0,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export interface ListContainerProps {
  children: ReactNode | ReactChildren;
  filterOpen?: boolean;
}

export const ListContainer = ({ children, filterOpen = true }: ListContainerProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        transition: 'margin-left 0.1s ease-in-out',
        marginLeft: filterOpen ? 0 : '-250px',
        position: 'relative',
        width: '100%',
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(2),
        pb: 0,
        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
          marginTop: filterOpen ? 0 : 'calc(425px - 100vh)',
          transition: 'margin-top 0.1s ease-in-out',
          pb: '54px',
        },
      }}
    >
      {children}
    </Box>
  );
};

export const ListHeader = styled(Box)(() => ({
  position: 'sticky',
  flexShrink: 0,
  padding: 0,
}));

export const ListContent = ({ children, ...props }: ComponentProps<typeof Box>) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
          height: 'calc(100vh - 205px)',
        },
      }}
    >
      {children}
    </Box>
  );
};

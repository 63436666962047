import { Tv } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCreateTVBrand } from '~/api/tv-brands/create';
import { SaveButton } from '~/components/button';
import { PageContainer } from '~/components/page-layout';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { TVBrandForm, validationSchema } from './detail/components';

export const NewTVBrand = () => {
  const { currentNetwork } = useAppContext();

  const navigate = useNavigate();

  const link = useLink();

  const [createTVBrand] = useCreateTVBrand();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      baudRate: '',
      mute: '',
      name: '',
      powerOff: '',
      powerOn: '',
      unmute: '',
      volumeDown: '',
      volumeUp: '',
    },
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await createTVBrand({
        variables: {
          input: {
            ...newValues,
            baudRate: Number(newValues.baudRate),
            networkId: currentNetwork.id,
          },
        },
      });
      navigate(link(`/tv-brands`));
    },
    validateOnMount: true,
    validationSchema,
  });

  return (
    <>
      <Toolbar
        actions={
          <SaveButton
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            type="submit"
            form="tv-brand-form"
          />
        }
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<Tv />}
        titleText="TV Brands"
      />

      <PageContainer>
        <TVBrandForm formik={formik} formId="tv-brand-form" />
      </PageContainer>
    </>
  );
};

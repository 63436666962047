import { createContext, useContext, useState, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import {
  type AppContext__Network as Network,
  type AppContext__CurrentUser as CurrentUser,
  type AppContext__TrueUser as TrueUser,
} from './queries.generated';

interface AppContextType {
  currentNetwork: Network;
  currentUser: CurrentUser;
  impersonating: boolean;
  setTransitioning: (transitioning: boolean) => void;
  transitioning: boolean;
  trueUser: TrueUser;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  assert(context !== undefined, 'useAppContext must be used within an AppContextProvider');
  return context;
};

interface AppContextProviderProps
  extends Omit<AppContextType, 'setTransitioning' | 'transitioning'> {
  children: ReactNode;
}

export const AppContextProvider = ({ children, ...props }: AppContextProviderProps) => {
  const [transitioning, setTransitioning] = useState(false);
  return (
    <AppContext.Provider value={{ ...props, setTransitioning, transitioning }}>
      {children}
    </AppContext.Provider>
  );
};

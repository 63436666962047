import { useQuery } from '@apollo/client';
import { ReceiptLong } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NetworkAuditLogsDocument } from '~/components/AuditLogs/queries/list.generated';
import { Count } from '~/components/count';
import { FilterSidebar, FilterToggle } from '~/components/Filter';
import { SearchBar } from '~/components/forms/search-bar';
import {
  ListActions,
  ListContainer,
  ListContent,
  ListHeader,
  ListPageContainer,
} from '~/components/PageLayout';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { FilterAccordion, FilterChips, Table } from './components';
import { useFilters, useSelectedFilterParams } from './lib';

export const AuditLogList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { recordTypes: selectedRecordTypes, userIds: selectedUserIds } = useSelectedFilterParams();

  const { data, loading } = useQuery(NetworkAuditLogsDocument, {
    variables: {
      networkId: currentNetwork.id,
      page,
      perPage,
      recordTypes: selectedRecordTypes,
      search,
      userIds: selectedUserIds,
    },
  });

  const auditUsers = data?.network?.userIds.nodes;
  const filterCounts = data?.network?.auditLogsFilterInfo;

  const { filters, clearFilters, setFilterItems, toggleAccordion, toggleItem, togglePanel } =
    useFilters(auditUsers);

  const panelOpen = filters.recordTypes.panelOpen || filters.userIds.panelOpen;

  const filtersActive = useMemo(
    () => selectedRecordTypes.length > 0 || selectedUserIds.length > 0,
    [selectedRecordTypes, selectedUserIds],
  );

  const totalCount = useTotalCount(data?.network?.auditLogs.pageInfo.nodesCount);

  return (
    <>
      <Toolbar
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<ReceiptLong />}
        titleText="Audit Logs"
      />
      <ListPageContainer>
        <FilterSidebar open={panelOpen}>
          <FilterAccordion
            clearFilter={clearFilters}
            filters={filters}
            filterCounts={filterCounts}
            toggleAccordion={toggleAccordion}
            updateFilter={setFilterItems}
          />
        </FilterSidebar>
        <ListContainer filterOpen={panelOpen}>
          <ListHeader>
            <ListActions>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FilterToggle
                  active={filtersActive}
                  onToggle={() => {
                    togglePanel('recordTypes');
                    togglePanel('userIds');
                  }}
                />
                <SearchBar
                  onChange={(value) =>
                    setSearchParams((params) => {
                      params.set('search', value);
                      params.set('page', '1');
                      return params;
                    })
                  }
                  placeholder="Search Shows"
                  search={search}
                />
              </Box>
              <Box sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center' }}>
                <Count selectedCount={0} totalCount={totalCount || 0} thing="audit log" />
              </Box>
            </ListActions>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  visibility: filtersActive ? 'visible' : 'hidden',
                }}
              >
                <FilterChips filters={filters} toggleItem={toggleItem} />
              </Box>
            </Box>
          </ListHeader>
          <ListContent>
            <Table
              auditLogs={data?.network?.auditLogs.nodes ?? []}
              loading={loading}
              totalCount={totalCount}
            />
          </ListContent>
        </ListContainer>
      </ListPageContainer>
    </>
  );
};

import { Tune } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

export const FilterHeader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        height: '64px',
        [theme.breakpoints.down('sm')]: {
          height: '50px',
        },
      }}
    >
      <Tune />
      <Typography sx={{ fontSize: 16 }}>Filter Options</Typography>
    </Box>
  );
};

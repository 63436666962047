import { Box, Tab, Tabs } from '@mui/material';
import { useMemo } from 'react';
import { StudioSettingsDataTab, StudioSettingsDetailsTab, StudioSettingsFormatTab } from '.';
import { useStudio } from '../../context';
import { settingsTabs } from '../../layout/utils';
import {
  StudioSidebarContainer,
  StudioSidebarScrollable,
  StudioSidebarScrollableInterior,
} from '../lib';

export const StudioSettings = () => {
  const { activeSettingsTab, selectedItems, setActiveSettingsTab } = useStudio();

  const displayTabs = useMemo(() => {
    const firstItem = selectedItems.at(0);
    if (!firstItem) return [];

    return settingsTabs.filter((tab) => {
      if (firstItem.type !== 'text' && tab.name === 'Data Source') return false;
      if (!['image', 'video'].includes(firstItem.type) && tab.name === 'Details') return false;
      return true;
    });
  }, [selectedItems]);

  if (selectedItems.length !== 1) return null;
  const selectedItem = selectedItems.at(0);
  if (!selectedItem) return null;

  return (
    <StudioSidebarContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Tabs
          value={activeSettingsTab}
          sx={{
            padding: (theme) => theme.spacing(1, 1, 0, 1),
          }}
        >
          {displayTabs.map(({ name }) => (
            <Tab key={name} label={name} onClick={() => setActiveSettingsTab(name)} value={name} />
          ))}
        </Tabs>
        <StudioSidebarScrollable>
          <StudioSidebarScrollableInterior>
            {activeSettingsTab === 'Settings' && (
              <StudioSettingsFormatTab selectedItem={selectedItem} />
            )}
            {activeSettingsTab === 'Data Source' && selectedItem.type === 'text' && (
              <StudioSettingsDataTab selectedItem={selectedItem} />
            )}
            {activeSettingsTab === 'Details' &&
              (selectedItem.type === 'image' || selectedItem.type === 'video') && (
                <StudioSettingsDetailsTab selectedItem={selectedItem} />
              )}
          </StudioSidebarScrollableInterior>
        </StudioSidebarScrollable>
      </Box>
    </StudioSidebarContainer>
  );
};

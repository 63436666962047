import Konva from 'konva';
import { type KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useMemo, useState } from 'react';
import { Circle, Group, Rect, RegularPolygon } from 'react-konva';
import { useStudio, type StudioItem } from '../../context';
import { StudioImage, StudioItemHover, StudioText, StudioVideo } from './';

export interface StudioBaseItemProps {
  item: StudioItem;
}

export const StudioBaseItem = ({ item }: StudioBaseItemProps) => {
  const { selectedItems, updateItems } = useStudio();

  const selected = useMemo(
    () => selectedItems.map((x) => x.id).includes(item.id),
    [item, selectedItems],
  );

  const [dragging, setDragging] = useState(false);
  const [mousing, setMousing] = useState(false);
  const [transforming, setTransforming] = useState(false);

  const showHover = useMemo(() => {
    if (transforming || dragging) return false; // TODO: Ideally we would show the hover when dragging
    if (selected || mousing) return true;
    return false;
  }, [dragging, mousing, selected, transforming]);

  const onDragStart = useCallback(() => {
    setDragging(true);
  }, []);

  const onDragEnd = useCallback(
    (event: KonvaEventObject<DragEvent>) => {
      updateItems([
        { id: item.id, x: Math.round(event.target.x()), y: Math.round(event.target.y()) },
      ]);
      setDragging(false);
    },
    [item, updateItems],
  );

  const onMouseOver = useCallback(() => {
    setMousing(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setMousing(false);
  }, []);

  const onTransform = useCallback((event: KonvaEventObject<Event>) => {
    // reset height and width instead of relying on scale, makes
    // certain attributes more predicatable (border, font size, etc)
    setTransforming(true);
    event.target.setAttrs({
      ...(event.target instanceof Konva.Text && {
        fontSize: event.target.fontSize() * event.target.scaleY(),
      }),
      ...(!(event.target instanceof Konva.Text) && {
        height: event.target.height() * event.target.scaleY(),
      }),
      scaleX: 1,
      scaleY: 1,
      width: event.target.width() * event.target.scaleX(),
    });
  }, []);

  const onTransformEnd = useCallback(
    (event: KonvaEventObject<Event>) => {
      setTransforming(false);
      updateItems([
        {
          id: item.id,
          ...(event.target instanceof Konva.Text && {
            fontSize: Math.round(event.target.fontSize()),
          }),
          ...(!(event.target instanceof Konva.Text) && {
            height: Math.round(event.target.height()),
          }),
          ...(event.target instanceof Konva.RegularPolygon && {
            radius: Math.round(event.target.radius()),
          }),
          rotation: Math.round(event.target.rotation()),
          scaleX: event.target.scaleX(),
          scaleY: event.target.scaleY(),
          width: Math.round(event.target.width()),
          x: Math.round(event.target.x()),
          y: Math.round(event.target.y()),
        },
      ]);
    },
    [item, updateItems],
  );

  const editProps = useMemo(
    () => ({
      onDragEnd,
      onDragStart,
      onMouseOver,
      onMouseOut,
      onTransform,
      onTransformEnd,
    }),
    [onDragEnd, onDragStart, onMouseOver, onMouseOut, onTransform, onTransformEnd],
  );

  return (
    <Group>
      <StudioItemHover hover={showHover} item={item} />
      {item.type === 'circle' && <Circle {...item} {...editProps} />}
      {item.type === 'image' && <StudioImage item={item} {...editProps} />}
      {item.type === 'rect' && <Rect {...item} {...editProps} />}
      {item.type === 'triangle' && <RegularPolygon {...item} {...editProps} />}
      {item.type === 'text' && <StudioText {...item} {...editProps} locked={!!item.__dataItemId} />}
      {item.type === 'video' && <StudioVideo item={item} {...editProps} />}
    </Group>
  );
};

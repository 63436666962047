import { Autorenew, Delete, SettingsPower, type SvgIconComponent } from '@mui/icons-material';
import { DeviceCommandKind } from '~/generated/graphql';

export interface Command {
  Icon: SvgIconComponent;
  command: DeviceCommandKind;
  description: string;
  payload?: object;
  title: string;
}

export const INITIAL_COMMANDS: Command[] = [
  {
    Icon: Delete,
    command: DeviceCommandKind.Purge,
    description:
      'Sending the delete content command will remove all downloaded content from the device.',
    title: 'Delete Content',
  },
  {
    Icon: SettingsPower,
    command: DeviceCommandKind.Reboot,
    description: 'Sending the reboot command will restart the entire device.',
    title: 'Reboot',
  },
  {
    Icon: Autorenew,
    command: DeviceCommandKind.Restart,
    description: 'Sending the restart command will restart the software running on the device.',
    title: 'Restart',
  },
];

import type Konva from 'konva';
import { useCallback, useState } from 'react';
import { usePublishDesign, useUpdateDesign } from '~/api/designs';
import { assert } from '~/lib/assert';
import { useStudio } from '../../context';
import { type DesignEdit__Design as Design } from '../DesignEdit.generated';

const exportStageThumbnail = async (design: Design, stage: Konva.Stage | undefined) => {
  assert(stage != null, 'No stage');

  // wait for transformer to disappear from any selected items
  await new Promise((r) => setTimeout(r, 100));
  const prev = {
    height: stage.height(),
    width: stage.width(),
    scaleX: stage.scaleX(),
    scaleY: stage.scaleY(),
  };
  stage.setAttrs({
    height: design.height,
    width: design.width,
    scaleX: 1,
    scaleY: 1,
  });
  const thumbnailUri = stage.toDataURL();
  stage.setAttrs(prev);

  return thumbnailUri;
};

export const usePublish = (design: Design) => {
  const { resetDirty, selectItems, selectedItems, stage } = useStudio();
  const [publishDesign] = usePublishDesign();
  const [loading, setLoading] = useState(false);

  const publish = useCallback(
    async (contentFolderId: number) => {
      setLoading(() => true);
      const currentlySelectedIds = selectedItems.map((x) => x.id);
      selectItems([]);

      const thumbnailUri = await exportStageThumbnail(design, stage);

      const result = await publishDesign({
        variables: { input: { contentFolderId, id: design.id, thumbnailUri } },
      });
      resetDirty();
      selectItems(currentlySelectedIds);
      setLoading(() => false);

      return result;
    },
    [design, publishDesign, resetDirty, selectItems, selectedItems, stage],
  );

  return [publish, loading] as const;
};

export const useSave = (design: Design) => {
  const { items, resetDirty, selectItems, selectedItems, stage } = useStudio();
  const [updateDesign] = useUpdateDesign();
  const [loading, setLoading] = useState(false);

  const save = useCallback(async () => {
    setLoading(() => true);

    const currentlySelectedIds = selectedItems.map((x) => x.id);

    // Deselect everything to remove the transformers because we don't want them in the thunbnail.
    selectItems([]);

    const thumbnailUri = await exportStageThumbnail(design, stage);

    const data = items.map((item) => {
      // remove text if it is a connected data item as it will be populated dynamically on load
      if (item.type === 'text' && item.__dataItemId && item.__dataItemKey)
        return { ...item, text: null };
      // remove fontFamily from text if using a connected font as it will be populated dynamically on load
      if (item.type === 'text' && item.__fontId) return { ...item, fontFamily: null };
      // remove src from image and video as it will be populated dynamically on load
      if (['image', 'video'].includes(item.type)) return { ...item, src: null };

      return item;
    });
    await updateDesign({ variables: { id: design.id, patch: { data, thumbnailUri } } });
    resetDirty();

    // Reselect what was selected before clicking save
    selectItems(currentlySelectedIds);

    setLoading(() => false);
  }, [design, items, resetDirty, selectItems, selectedItems, stage, updateDesign]);

  return [save, loading] as const;
};

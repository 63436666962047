import {
  AccessTime,
  Event,
  Folder,
  Image,
  ImageAspectRatio,
  Layers,
  Movie,
  Link as MuiLink,
  PhotoSizeSelectActual,
  Sell,
} from '@mui/icons-material';
import { useUpdateMediaItem } from '~/api/media-items';
import {
  DetailAccordion,
  DetailAccordionContainer,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import { DetailSection } from '~/components/DetailSection';
import { DetailSwitch } from '~/components/forms/details';
import { Link } from '~/components/link';
import { TagList } from '~/components/tag';
import { MediaKind } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import { formatDate, formatInterval } from '~/lib/datetime';
import { mediaDimensions, mediaExtension, mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import type { MediaItemInfoProps__MediaItem } from './MediaItemInfo.generated';

/* GraphQL */ `#graphql
fragment MediaItemInfoProps__MediaItem on MediaItem {
  createdAt
  defaultDuration {
    ...Interval
  }
  folder {
    id
    name
  }
  id
  kind
  originalFile {
    id
    originalFilename
    uri
  }
  originalMetadata
  overlay {
    id
  }
  name
  tags
  thumbnails {
    boundsHeight
    boundsWidth
    uri
  }
  updatedAt
  useOriginal
}
`;

export interface MediaItemInfoProps {
  displayAppLink?: boolean;
  displayTags?: boolean;
  displayUseOriginal?: boolean;
  mediaItem: MediaItemInfoProps__MediaItem;
}

export const MediaItemInfo = ({
  displayAppLink = false,
  displayTags = false,
  displayUseOriginal = false,
  mediaItem,
}: MediaItemInfoProps) => {
  const [updateMediaItem] = useUpdateMediaItem();

  const link = useLink();

  return (
    <DetailAccordionContainer>
      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Info</DetailAccordionSummary>
        <DetailAccordionDetails>
          {displayAppLink ? (
            <DetailSection
              icon={<MuiLink />}
              title="Media"
              value={
                <Link to={link(`/content/${mediaItem.folder.id}/media/${mediaItem.id}/details`)}>
                  {mediaItem.name}
                </Link>
              }
            />
          ) : (
            <DetailSection
              icon={<MuiLink />}
              title="File"
              value={
                <Link to={mediaItem.originalFile.uri} target="_blank">
                  {mediaItem.originalFile.originalFilename}
                </Link>
              }
            />
          )}
          <DetailSection
            icon={mediaItem.kind === 'IMAGE' ? <Image /> : <Movie />}
            title="Format"
            value={mediaExtension(mediaItem)}
          />
          <DetailSection
            icon={<Folder />}
            title="Folder"
            value={
              // There's no reason not to link to the root folder here
              // And this check is bad
              mediaItem.folder.name === 'No Folder' ? (
                'N/A'
              ) : (
                <Link to={link(`/content/${mediaItem.folder.id}`)}>{mediaItem.folder.name}</Link>
              )
            }
          />
          <DetailSection icon={<Event />} title="Created" value={formatDate(mediaItem.createdAt)} />
          <DetailSection icon={<Event />} title="Updated" value={formatDate(mediaItem.updatedAt)} />
        </DetailAccordionDetails>
      </DetailAccordion>

      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Specs</DetailAccordionSummary>
        <DetailAccordionDetails>
          <DetailSection icon="Kb" title="Size" value={formatBytes(mediaSize(mediaItem))} />
          <DetailSection
            icon={<ImageAspectRatio />}
            title="Dimensions"
            value={mediaDimensions(mediaItem).join('x')}
          />
          {mediaItem.kind === MediaKind.Video && (
            <DetailSection
              icon={<AccessTime />}
              title="Duration"
              value={formatInterval(mediaItem.defaultDuration)}
            />
          )}
        </DetailAccordionDetails>
      </DetailAccordion>

      {(displayTags || displayUseOriginal) && (
        <DetailAccordion defaultExpanded>
          <DetailAccordionSummary>Misc</DetailAccordionSummary>
          <DetailAccordionDetails>
            {displayUseOriginal && (
              <DetailSection
                icon={<PhotoSizeSelectActual />}
                title="Use Original?"
                value={
                  <DetailSwitch
                    defaultChecked={mediaItem.useOriginal}
                    disabled={mediaItem.kind === 'PDF'}
                    onChange={(event) =>
                      void updateMediaItem({
                        variables: {
                          mediaItemId: mediaItem.id,
                          patch: { useOriginal: event.target.checked },
                        },
                      })
                    }
                    size="small"
                  />
                }
              />
            )}
            {displayTags && (
              <DetailSection
                icon={<Sell />}
                title="Tags"
                value={
                  mediaItem.tags.length ? (
                    <TagList
                      item={mediaItem}
                      sx={{ justifyContent: 'flex-end' }}
                      tags={mediaItem.tags}
                    />
                  ) : (
                    '--'
                  )
                }
              />
            )}
            {mediaItem.overlay && (
              <DetailSection icon={<Layers />} title="Overlay ID" value={mediaItem.overlay.id} />
            )}
          </DetailAccordionDetails>
        </DetailAccordion>
      )}
    </DetailAccordionContainer>
  );
};

import { Group, VerifiedUser } from '@mui/icons-material';
import { NavGroup } from '../NavGroup';

export const UserNav = ({ onClick }: { onClick: () => void }) => {
  return (
    <NavGroup
      title="User Management"
      items={[
        {
          icon: <Group />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Users',
          to: '/users',
        },
        {
          icon: <VerifiedUser />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Roles',
          to: '/roles',
        },
      ]}
    />
  );
};

import { Box, Chip } from '@mui/material';
import type { FilterItem } from '../lib';

interface FilterChipsProps {
  items: FilterItem[];
  toggleItem: (value: string) => void;
}

export const FilterChips = ({ items, toggleItem }: FilterChipsProps) => (
  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
    {items
      .filter((f) => f.selected)
      .map((f) => (
        <Chip
          key={`tag-${f.value}`}
          label={`Tag: ${f.label}`}
          color="primary"
          onDelete={() => {
            // Toggle off this tag
            toggleItem(f.value);
          }}
        />
      ))}
  </Box>
);

import { Assessment, ReceiptLong } from '@mui/icons-material';
import { NavGroup } from '../NavGroup';

export const ReportingNav = ({ onClick }: { onClick: () => void }) => {
  return (
    <NavGroup
      title="Reporting"
      items={[
        {
          icon: <Assessment />,
          onClick: onClick,
          permission: 'canAccessReporting',
          text: 'Playout Reporting',
          to: '/reporting',
        },
        {
          icon: <ReceiptLong />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Audit Logs',
          to: '/audit-logs',
        },
      ]}
    />
  );
};

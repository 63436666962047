import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip } from '@mui/material';
import { type ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '~/contexts';
import { useSideNav } from '../context';
import { NavItemLink } from './NavItems';

export const isNavItemSelected = (pathname: string, basePath: string) => {
  const normalizedBasePath = basePath.replace(/^\/+/, ''); // Remove leading slash if present
  return new RegExp(`^/networks/\\d+/${normalizedBasePath}`).test(pathname);
};

type NavItemPermission = Extract<
  keyof ReturnType<typeof useAppContext>['currentNetwork'],
  `can${string}`
>;

export interface NavItem {
  permission: NavItemPermission;
  icon: ReactNode;
  onClick?: () => void;
  selected?: string; // required for special cases, e.g. channels href but channel guides should be selected
  text: string;
  to: string;
}

export interface NavGroupProps {
  initialOpen?: boolean;
  items: NavItem[];
  title: string;
}

export const NavGroup = ({ items, initialOpen = false, title }: NavGroupProps) => {
  const { open: sideNavOpen } = useSideNav();

  const { currentNetwork } = useAppContext();

  const location = useLocation();

  const navItems = useMemo(
    () =>
      items
        .filter((item) => currentNetwork[item.permission].value)
        .map((item) => ({
          ...item,
          selected: isNavItemSelected(location.pathname, item.selected ?? item.to),
        })),
    [items, currentNetwork, location.pathname],
  );

  const [open, setOpen] = useState(
    () => initialOpen || navItems.some((item) => item.selected) || navItems.length === 1,
  );

  if (navItems.length === 0) return null;

  return (
    <Accordion
      disableGutters
      onChange={() => setOpen((prev) => !prev)}
      expanded={open}
      sx={{
        backgroundColor: 'transparent',
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          sideNavOpen ? (
            <ExpandMore />
          ) : (
            <Tooltip arrow title={title} placement="right">
              <ExpandMore />
            </Tooltip>
          )
        }
        sx={{
          backgroundColor: 'transparent',
          color: 'common.white',
          '& svg': {
            color: 'common.white',
          },
          '.MuiAccordionSummary-content': {
            fontSize: '.8rem',
            fontSpacing: '1px',
            fontWeight: 600,
            textTransform: 'uppercase',
          },
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: (theme) => theme.spacing(0),
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {navItems.map((item, index) => (
            <NavItemLink key={index} {...item} />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

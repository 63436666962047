import { DriveFileRenameOutline, Folder } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import type { ContentFolderView__ContentFolder } from '../ContentFolderView.generated';

export interface ContentMenuContentFolderProps {
  item: ContentFolderView__ContentFolder;
  onMove: () => void;
  onRename: () => void;
}

export const ContentMenuContentFolder = ({
  item,
  onMove,
  onRename,
}: ContentMenuContentFolderProps) => (
  <span>
    <MenuItem disabled={!item.canUpdate.value} onClick={onMove}>
      <ListItemIcon>
        <Folder />
      </ListItemIcon>
      <ListItemText>Move to...</ListItemText>
    </MenuItem>
    <MenuItem disabled={!item.canUpdate.value} onClick={onRename}>
      <ListItemIcon>
        <DriveFileRenameOutline />
      </ListItemIcon>
      <ListItemText>Rename</ListItemText>
    </MenuItem>
  </span>
);

import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { NetworkAuditLogs__User as User } from '~/components/AuditLogs/queries/list.generated';

export interface FilterItem {
  label: string;
  value: string;
  selected: boolean;
  count?: number;
}

export interface FilterState {
  expanded: boolean;
  items: FilterItem[];
  panelOpen: boolean;
}

export interface FiltersState {
  recordTypes: FilterState;
  userIds: FilterState;
}

const INITIAL_RECORDTYPES: FilterItem[] = [
  { label: 'Channel', selected: false, value: 'Channel' },
  { label: 'Channel Guide', selected: false, value: 'ChannelGuide' },
  { label: 'Content Item', selected: false, value: 'ContentItem' },
  { label: 'Content Folder', selected: false, value: 'ContentFolder' },
  { label: 'Data Source', selected: false, value: 'DataSource' },
  { label: 'Design', selected: false, value: 'Design' },
  { label: 'Device', selected: false, value: 'Device' },
  { label: 'Device Group', selected: false, value: 'DeviceGroup' },
  { label: 'Event', selected: false, value: 'Event' },
  { label: 'Event Type', selected: false, value: 'EventType' },
  { label: 'Font', selected: false, value: 'Font' },
  { label: 'Layout', selected: false, value: 'Layout' },
  { label: 'Media Item', selected: false, value: 'MediaItem' },
  { label: 'Network', selected: false, value: 'Network' },
  { label: 'Overlay', selected: false, value: 'Overlay' },
  { label: 'Playlist', selected: false, value: 'Playlist' },
  { label: 'Property Group', selected: false, value: 'PropertyGroup' },
  { label: 'Role', selected: false, value: 'Role' },
  { label: 'Show', selected: false, value: 'Show' },
  { label: 'TV Brand', selected: false, value: 'TVBrand' },
  { label: 'User', selected: false, value: 'User' },
  { label: 'Zone', selected: false, value: 'Zone' },
];

export const useSelectedFilterParams = () => {
  const [searchParams] = useSearchParams();
  return {
    recordTypes: JSON.parse(searchParams.get('record-types') || '[]') as string[],
    userIds: (JSON.parse(searchParams.get('users') || '[]') as string[]).map(Number),
  };
};

export const useFilters = (initialUsers: readonly User[] | undefined) => {
  const { recordTypes: recordTypesParams, userIds: userIdsParams } = useSelectedFilterParams();

  const initRecordTypeState = useCallback((): FilterState => {
    return {
      expanded: true,
      items: INITIAL_RECORDTYPES.map((item) => ({
        label: item.label,
        value: item.value,
        selected: recordTypesParams.includes(item.value),
      })),
      panelOpen: recordTypesParams.length > 0,
    };
  }, [recordTypesParams]);

  const initUserIdsState = useCallback((): FilterState => {
    return {
      expanded: true,
      items: initialUsers
        ? initialUsers.map((user) => ({
            label: user.name,
            value: user.id.toString(),
            selected: userIdsParams.includes(user.id),
          }))
        : [],
      panelOpen: userIdsParams.length > 0,
    };
  }, [initialUsers, userIdsParams]);

  const [filters, setFilters] = useState<FiltersState>({
    recordTypes: initRecordTypeState(),
    userIds: initUserIdsState(),
  });

  const initialUsersRef = useRef<readonly User[] | undefined>(initialUsers);

  useEffect(() => {
    if (!initialUsers || initialUsers.length === 0) return;
    if (initialUsersRef.current !== initialUsers) {
      setFilters((prev) => ({ ...prev, userIds: initUserIdsState() }));
      initialUsersRef.current = initialUsers;
    }
  }, [initialUsers, initUserIdsState]);

  const [_, setSearchParams] = useSearchParams();

  const updateSearchParams = useCallback(
    (key: keyof FiltersState, items: FilterItem[]) => {
      const urlKeys: Record<keyof FiltersState, string> = {
        recordTypes: 'record-types',
        userIds: 'users',
      };
      setSearchParams((params) => {
        const selected = items.filter((x) => x.selected);
        if (selected.length > 0) {
          params.set(urlKeys[key], JSON.stringify(selected.map((x) => x.value)));
        } else {
          params.delete(urlKeys[key]);
        }
        params.set('page', '1'); // reset pagination when filters change
        return params;
      });
    },
    [setSearchParams],
  );

  const clearFilters = useCallback(() => {
    setFilters((current) => ({
      recordTypes: {
        ...current.recordTypes,
        expanded: true,
        items: current.recordTypes.items.map((item) => ({ ...item, selected: false })),
        panelOpen: false,
      },
      userIds: {
        ...current.userIds,
        expanded: true,
        items: current.userIds.items.map((item) => ({ ...item, selected: false })),
        panelOpen: false,
      },
    }));
    setSearchParams((params) => {
      params.delete('record-types');
      params.delete('users');
      params.set('page', '1');
      return params;
    });
  }, [setSearchParams]);

  const setFilterItems = useCallback(
    (key: keyof FiltersState, newItems: FilterItem[]) => {
      setFilters((x) => ({ ...x, [key]: { ...x[key], items: newItems } }));
      updateSearchParams(key, newItems);
    },
    [updateSearchParams],
  );

  const toggleAccordion = useCallback((key: keyof FiltersState) => {
    setFilters((x) => ({ ...x, [key]: { ...x[key], expanded: !x[key].expanded } }));
  }, []);

  const toggleItem = useCallback(
    (key: keyof FiltersState, value: string) => {
      const newItems = filters[key].items.map((item) =>
        item.value === value ? { ...item, selected: !item.selected } : item,
      );
      setFilters((prev) => ({ ...prev, [key]: { ...prev[key], items: newItems } }));
      updateSearchParams(key, newItems);
    },
    [filters, updateSearchParams],
  );

  const togglePanel = useCallback((key: keyof FiltersState) => {
    setFilters((current) => ({
      ...current,
      [key]: { ...current[key], panelOpen: !current[key].panelOpen },
    }));
  }, []);

  return {
    filters,
    clearFilters,
    setFilterItems,
    toggleAccordion,
    toggleItem,
    togglePanel,
  };
};

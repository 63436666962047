import { Button } from '@mui/material';
import { useState } from 'react';
import { useCreateContentFolder } from '~/api/content-folders';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import {
  DialogActions,
  DialogContent,
  DialogFieldLabel,
  DialogFieldRequired,
} from '~/components/dialogs/lib/styles';
import { DetailTextField } from '~/components/forms/details';

interface AddFolderDialogProps {
  contentFolderId: number;
  openNewFolderDialog: boolean;
  setOpenNewFolderDialog: (val: boolean) => void;
}

export const AddFolderDialog = ({
  contentFolderId,
  openNewFolderDialog,
  setOpenNewFolderDialog, // refetch,
}: AddFolderDialogProps) => {
  const [folderNameTouched, setFolderNameTouched] = useState(false);

  const [folderName, setFolderName] = useState('');

  const folderNameValid = folderName.trim() !== '';

  const [create, createState] = useCreateContentFolder();

  const handleClose = () => {
    setOpenNewFolderDialog(false);
    setFolderNameTouched(false);
    setFolderName('');
    createState.reset();
  };

  const onAddFolder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await create({ variables: { name: folderName, parentId: contentFolderId } });
    handleClose();
  };

  return (
    <Dialog
      open={openNewFolderDialog}
      onClose={handleClose}
      aria-label="Add Folder"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={handleClose}>Add Folder</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Folders may be used to organize your media.</DialogContentTitle>
        <DialogFieldLabel>
          Folder Name<DialogFieldRequired>*</DialogFieldRequired>
        </DialogFieldLabel>
        <DialogForm id="new-folder" onSubmit={onAddFolder}>
          <DetailTextField
            autoFocus
            placeholder="e.g. My New Folder"
            onBlur={() => setFolderNameTouched(true)}
            error={folderNameTouched && !folderNameValid}
            value={folderName}
            onChange={(event) => setFolderName(event.target.value)}
            sx={{ mt: 1 }}
          />
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!folderNameValid || createState.loading}
          form="new-folder"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { KeyboardArrowDown, RemoveCircle } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useRef, useState } from 'react';
import { useDeletePlaylistItem } from '~/api/playlist-items';
import { BulkActionButton, BulkActionMenuItem } from '~/components/bulk-actions';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';

export type BulkActionsMenuProps = {
  playlistId: number;
  selectedIds: GridRowSelectionModel;
};

export const BulkActionsMenu = ({ playlistId, selectedIds }: BulkActionsMenuProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [deletePlaylistItem] = useDeletePlaylistItem({
    variables: {
      playlistId,
      playlistItemIds: selectedIds.map((id) => +id),
    },
  });

  if (!selectedIds.length) return null;

  return (
    <>
      <BulkActionButton
        ref={ref}
        disableRipple
        id="bulk-actions-button"
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(true)}
      >
        Actions...
        <KeyboardArrowDown />
      </BulkActionButton>
      <Menu
        id="bulk-actions-menu"
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <BulkActionMenuItem
          onClick={async () => {
            setOpen(false);
            if (!(await confirmDelete())) return;
            await deletePlaylistItem();
          }}
        >
          <ListItemIcon>
            <RemoveCircle color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>Remove</ListItemText>
        </BulkActionMenuItem>
      </Menu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove"
        deleteConfirm
        deleteIcon={<RemoveCircle />}
        prompt="Removing these items will remove them from this Playlist. Are you sure?"
        title="Remove Content"
      />
    </>
  );
};

import { Check, Info, Warning } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { Link } from '~/components/link';
import { AuthorizedLink } from '~/components/links';
import type { ShowAutocomplete__Show as Show } from '~/components/ShowAutocomplete';
import { ShowAutocomplete } from '~/components/ShowAutocomplete';
import { useLink } from '~/hooks/link';
import ActivateDeviceIllustration from '~/images/illustrations/activate-device.svg';
import type {
  NetworkEventTypeShows__DeviceGroup as DeviceGroup,
  NetworkEventTypeShows__EventType as EventType,
} from '../../queries/shows.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={ActivateDeviceIllustration}
    description="This network currently does not have any device groups."
    header="Add Device Groups"
  />
);

const ShowStatus = ({
  deviceGroup,
  eventType,
  showId,
}: {
  deviceGroup: DeviceGroup;
  eventType: EventType;
  showId: number | null;
}) => {
  const defaultShow = deviceGroup.defaultShow;

  const title =
    showId !== -1
      ? `The selected show will play for this device group during ${eventType.name} events.`
      : defaultShow
      ? `The default show for this device group, ${defaultShow.name}, will play during ${eventType.name} events since no show has been selected to play for this device group.`
      : `No show will play for this device group during ${eventType.name} events.`;

  const icon =
    showId !== -1 ? (
      <Check className="icon" color="success" fontSize="medium" />
    ) : defaultShow ? (
      <Info className="icon" color="info" fontSize="medium" />
    ) : (
      <Warning className="icon" color="warning" fontSize="medium" />
    );

  return <Tooltip title={title}>{icon}</Tooltip>;
};

export interface DeviceGroupColumnProps {
  getShow: (id: number) => number | null;
  eventType: EventType;
  setShow: (id: number, value: Show | null) => void;
}

const useColumns = ({ getShow, eventType, setShow }: DeviceGroupColumnProps): GridColDef[] => {
  const link = useLink();

  const getActiveShow = useCallback(
    (eventType: EventType, deviceGroupId: number) => {
      const show =
        eventType.showDeviceGroups?.find((x) => x.show.id === getShow(deviceGroupId))?.show ?? null;
      return show;
    },
    [getShow],
  );

  return useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Device Group',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => (
          <AuthorizedLink authorized={row.canUpdate.value} entity={row} />
        ),
        flex: 1,
      },
      {
        field: 'deviceCount',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return (
            <Chip
              color="primary"
              component={Link}
              label={row.deviceCount || 0}
              onClick={(event) => event.stopPropagation()}
              to={link(`/device-groups/${row.id}/devices`)}
            />
          );
        },
        minWidth: 250,
      },
      {
        field: 'status',
        headerName: '',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return <ShowStatus deviceGroup={row} eventType={eventType} showId={getShow(row.id)} />;
        },
        maxWidth: 50,
      },
      {
        field: 'id',
        headerName: 'Assigned Show',
        flex: 0.5,
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return (
            <ShowAutocomplete
              onChange={(newValue: Show | null) => setShow(row.id, newValue)}
              value={getActiveShow(eventType, row.id)}
              size="small"
              sx={{
                flexBasis: '100%',
                display: 'inline-flex',
              }}
            />
          );
        },
      },
    ],
    [link, eventType, getShow, getActiveShow, setShow],
  );
};

export interface DeviceGroupTableProps {
  deviceGroups: readonly DeviceGroup[];
  eventType: EventType;
  getShow: (id: number) => number | null;
  loading: boolean;
  setShow: (id: number, value: Show | null) => void;
}

export const DeviceGroupTable = ({
  deviceGroups,
  eventType,
  getShow,
  loading,
  setShow,
}: DeviceGroupTableProps) => {
  const columns = useColumns({ getShow, eventType, setShow });
  return (
    <ReadOnlyDataGrid
      columns={columns}
      loading={loading}
      disableRowSelectionOnClick
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      rows={deviceGroups}
    />
  );
};

import { CalendarMonth, Schedule, Stadium } from '@mui/icons-material';
import { NavGroup } from '../NavGroup';

export const SchedulingNav = ({ onClick }: { onClick: () => void }) => {
  return (
    <NavGroup
      title="Scheduling"
      items={[
        {
          icon: <CalendarMonth />,
          onClick: onClick,
          permission: 'canAccessScheduler',
          text: 'Events',
          to: '/events',
        },
        {
          icon: <Schedule />,
          onClick: onClick,
          permission: 'canAccessScheduler',
          text: 'Manual',
          to: '/schedules',
        },
        {
          icon: <Stadium />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Event Types',
          to: '/event-types',
        },
      ]}
    />
  );
};

import { DeviceHub, Router, Tv } from '@mui/icons-material';
import { useLocalStorage } from 'usehooks-ts';
import { NavGroup } from '../NavGroup';

export const DeviceNav = ({ onClick }: { onClick: () => void }) => {
  const [deviceFilters] = useLocalStorage('devices:filters', '');
  return (
    <NavGroup
      title="Device Management"
      items={[
        {
          icon: <Router />,
          onClick: onClick,
          permission: 'canAccessDevices',
          selected: 'devices',
          text: 'Devices',
          to: deviceFilters ? `/devices?${deviceFilters}` : '/devices',
        },
        {
          icon: <DeviceHub />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Device Groups',
          to: '/device-groups',
        },
        {
          icon: <Tv />,
          onClick: onClick,
          permission: 'canManage',
          text: 'TV Brands',
          to: '/tv-brands',
        },
      ]}
      initialOpen={true}
    />
  );
};

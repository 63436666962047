import { useFragment } from '@apollo/client';
import { Typography } from '@mui/material';
import { useMatch, useParams } from 'react-router-dom';
import { Link, type LinkProps } from '~/components/link';
import { useLink } from '~/hooks/link';
import { DeviceGroupBreadcrumb__DeviceGroup } from './breadcrumbs.generated';

/* GraphQL */ `#graphql
fragment DeviceGroupBreadcrumb__DeviceGroup on DeviceGroupNew {
  id
  name
}
`;

export const DeviceGroupsBreadcrumb = (props: Omit<LinkProps, 'to'>) => {
  const link = useLink();
  const match = useMatch('/networks/:networkId/settings/device-groups');

  return match == null ? (
    <Link color="inherit" to={link('/device-groups')} {...props}>
      Device Groups
    </Link>
  ) : (
    <Typography>Device Groups</Typography>
  );
};

export const DeviceGroupBreadcrumb = ({ ...props }: Omit<LinkProps, 'to'>) => {
  const { deviceGroupId } = useParams<{ deviceGroupId: string }>();

  const { complete, data } = useFragment({
    fragment: DeviceGroupBreadcrumb__DeviceGroup,
    fragmentName: 'DeviceGroupBreadcrumb__DeviceGroup',
    from: { __typename: 'DeviceGroupNew', id: deviceGroupId },
  });

  const link = useLink();

  const match = useMatch('/networks/:networkId/settings/device-groups/:deviceGroupId/details');

  if (!complete) return null;

  return match == null ? (
    <Link color="inherit" to={link(`/device-groups/${deviceGroupId}`)} {...props}>
      {data.name}
    </Link>
  ) : (
    <Typography>{data.name}</Typography>
  );
};

export interface DeviceGroupListBreadcrumbProps extends Omit<LinkProps, 'to'> {
  segmentLink: string;
  segmentTitle: string;
}

export const DeviceGroupListBreadcrumb = ({
  segmentLink,
  segmentTitle,
  ...props
}: DeviceGroupListBreadcrumbProps) => {
  const { deviceGroupId } = useParams<{ deviceGroupId: string }>();

  const link = useLink();

  const match = useMatch(
    `/networks/:networkId/settings/device-groups/:deviceGroupId/${segmentLink}`,
  );

  return match == null ? (
    <Link color="inherit" to={link(`/device-groups/${deviceGroupId}/${segmentLink}`)} {...props}>
      {segmentTitle}
    </Link>
  ) : (
    <Typography>{segmentTitle}</Typography>
  );
};

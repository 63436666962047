import { useMutation } from '@apollo/client';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload as ManagedUpload } from '@aws-sdk/lib-storage';
import { LoadingButton } from '@mui/lab';
import { Button, LinearProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import { CreateUploadConfigDocument } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import type { MediaItemMenuProps__MediaItem } from './MediaItemMenu.generated';

export interface ReplaceMediaItemDialogProps {
  appCount: number;
  designCount: number;
  item: MediaItemMenuProps__MediaItem;
  onClose: () => void;
  onReplace: () => void;
  open: boolean;
}

export const ReplaceMediaItemDialog = ({
  appCount,
  designCount,
  item,
  onClose,
  onReplace,
  open,
}: ReplaceMediaItemDialogProps) => {
  const { currentNetwork } = useAppContext();
  const [progress, setProgress] = useState<number | null>(null);
  const [createUploadConfig] = useMutation(CreateUploadConfigDocument);

  const resetState = useCallback(() => {
    onReplace();
    onClose();
    setProgress(() => null);
  }, [onClose, onReplace]);

  const { getInputProps, open: selectFile } = useDropzone({
    accept: item.kind === 'PDF' ? 'application/pdf' : `${item.kind}/*`,
    maxSize: 2 ** 30, // 1 GiB
    minSize: 1,
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      const file = accepted.at(0);
      if (!file) return;

      setProgress(() => 0);

      const { data } = await createUploadConfig({
        variables: {
          networkId: currentNetwork.id,
        },
      });
      assert(data?.createUploadConfig != null, 'Could not retrieve upload credentials');

      const { bucket, credentials, metadata, region } = data.createUploadConfig;
      const expiration = new Date(credentials.expiration);
      const client = new S3Client({ credentials: { ...credentials, expiration }, region });
      const manager = new ManagedUpload({
        client,
        params: {
          Body: file,
          Bucket: bucket,
          ContentType: file.type,
          Key: `uploads/media/${uuid()}`,
          Metadata: {
            ...metadata,
            filename: encodeURIComponent(file.name),
            mediaItemId: item.id.toString(),
          },
        },
      });
      manager.on('httpUploadProgress', (progress) => {
        if (progress.total) {
          setProgress((100 * (progress.loaded ?? 0)) / progress.total);
        }
      });
      manager.done().catch((error) => {
        console.error(error);
        resetState();
      });
    },
  });

  useEffect(() => {
    if (progress === 100) setTimeout(resetState, 1000);
  }, [progress, resetState]);

  return (
    <Dialog aria-label="replace media item" fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Replace Media Item</DialogTitle>
      <DialogContent>
        {progress !== null ? (
          <LinearProgress color="primary" value={progress} variant="determinate" />
        ) : (
          <DialogContentTitle>
            This Media Item is used in{' '}
            <strong>
              {appCount} App{appCount === 1 ? '' : 's'}
            </strong>{' '}
            and{' '}
            <strong>
              {designCount} Design{designCount === 1 ? '' : 's'}
            </strong>
            . This will update the media item everywhere that it is currently used.
          </DialogContentTitle>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <input {...getInputProps()} />
        <LoadingButton
          color="primary"
          loading={progress !== null}
          onClick={selectFile}
          variant="contained"
        >
          Replace File
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { Box, Chip } from '@mui/material';
import type { FiltersState } from '../lib';

interface FilterChipsProps {
  filters: FiltersState;
  toggleItem: (key: keyof FiltersState, value: string) => void;
}

export const FilterChips = ({ filters, toggleItem }: FilterChipsProps) => (
  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
    {filters.recordTypes.items
      .filter((f) => f.selected)
      .map((f) => (
        <Chip
          key={`record-type-${f.value}`}
          label={`Record Type: ${f.label}`}
          color="primary"
          onDelete={() => toggleItem('recordTypes', f.value)}
        />
      ))}
    {filters.userIds.items
      .filter((f) => f.selected)
      .map((f) => (
        <Chip
          key={`user-${f.value}`}
          label={`User: ${f.label}`}
          color="primary"
          onDelete={() => toggleItem('userIds', f.value)}
        />
      ))}
  </Box>
);

import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { MediaItemThumbnailsList } from './components/MediaItemThumbnailsList';
import { MediaItemThumbnailsDocument } from './MediaItemThumbnails.generated';

export const MediaItemThumbnails = () => {
  const params = useParams<{ mediaItemId: string }>();
  const mediaItemId = parseInt(params.mediaItemId ?? '');
  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(MediaItemThumbnailsDocument, {
    fetchPolicy: 'cache-first',
    variables: { id: mediaItemId, networkId: currentNetwork.id },
  });

  if ((!loading && !data) || error) return <Navigate to="/not-found" />;

  const mediaItem = data?.network?.mediaItem;

  return (
    <LoadingPane in={loading && !data}>
      {mediaItem && <MediaItemThumbnailsList mediaItem={mediaItem} />}
    </LoadingPane>
  );
};

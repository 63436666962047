import { ContentCopy, Edit, Folder, Layers, LayersClear, Update } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemLink } from '~/components/link';
import { useLink } from '~/hooks/link';
import type { ContentFolderView__ContentItem } from '../ContentFolderView.generated';

export interface ContentMenuContentItemProps {
  item: ContentFolderView__ContentItem;
  onCopy: () => void;
  onMove: () => void;
  onOverlay: () => void;
  onRemoveOverlay: () => void;
  onUpgrade: () => void;
}

export const ContentMenuContentItem = ({
  item,
  onCopy,
  onMove,
  onOverlay,
  onRemoveOverlay,
  onUpgrade,
}: ContentMenuContentItemProps) => {
  const link = useLink();
  return (
    <span>
      {!item.design && (
        <MenuItem onClick={onCopy}>
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
      )}

      <MenuItemLink
        to={
          item.design?.id
            ? link(`/studio/${item.design.id}`)
            : link(`/content/${item.nullableContentFolderId}/apps/${item.id}/settings`)
        }
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItemLink>

      <MenuItem disabled={!item.canUpdate.value} onClick={onMove}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText>Move to...</ListItemText>
      </MenuItem>

      {item.overlay ? (
        <MenuItem onClick={onRemoveOverlay}>
          <ListItemIcon>
            <LayersClear />
          </ListItemIcon>
          <ListItemText>Remove Overlay</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem onClick={onOverlay}>
          <ListItemIcon>
            <Layers />
          </ListItemIcon>
          <ListItemText>Use as Overlay</ListItemText>
        </MenuItem>
      )}

      <MenuItem disabled={!item.canUpgrade.value} onClick={onUpgrade}>
        <ListItemIcon>
          <Update />
        </ListItemIcon>
        <ListItemText>Upgrade</ListItemText>
      </MenuItem>
    </span>
  );
};

import { useQuery } from '@apollo/client';
import { LoadingPane } from '~/components/loading-pane';
import { MediaItemDetailsDocument } from '~/components/media/MediaItemDetails.generated';
import { MediaItemInfo } from '~/components/media/MediaItemInfo';
import { useAppContext } from '~/contexts';
import type { StudioItem } from '../../context';

export interface StudioSettingsDetailsTabProps {
  selectedItem: StudioItem;
}

export const StudioSettingsDetailsTab = ({ selectedItem }: StudioSettingsDetailsTabProps) => {
  const { currentNetwork } = useAppContext();

  const mediaItemId = +selectedItem.__mediaItemId;

  const { data, loading } = useQuery(MediaItemDetailsDocument, {
    fetchPolicy: 'cache-first',
    variables: { id: mediaItemId, networkId: currentNetwork.id },
  });

  const mediaItem = data?.network?.mediaItem;

  if (!mediaItem) return null;

  return (
    <LoadingPane in={loading && !data}>
      <MediaItemInfo mediaItem={mediaItem} displayAppLink={true} />
    </LoadingPane>
  );
};

import {
  AppRegistration,
  AutoFixHigh,
  CropOriginal,
  FontDownload,
  MovieCreation,
  OndemandVideo,
  ViewComfy,
} from '@mui/icons-material';
import { NavGroup } from '../NavGroup';

export const ContentNav = ({ onClick }: { onClick: () => void }) => {
  return (
    <NavGroup
      title="Content Management"
      items={[
        {
          icon: <CropOriginal />,
          onClick: onClick,
          permission: 'canAccessContent',
          text: 'Content',
          to: '/content',
        },
        {
          icon: <OndemandVideo />,
          onClick: onClick,
          permission: 'canAccessPlaylists',
          text: 'Playlists',
          to: '/playlists',
        },
        {
          icon: <MovieCreation />,
          onClick: onClick,
          permission: 'canAccessShows',
          text: 'Shows',
          to: '/shows',
        },
        {
          icon: <AutoFixHigh />,
          onClick: onClick,
          permission: 'canAccessStudio',
          text: 'Studio',
          to: '/studio',
        },
        {
          icon: <ViewComfy />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Layouts',
          to: '/layouts',
        },
        {
          icon: <AppRegistration />,
          onClick: onClick,
          permission: 'canAccessTemplates',
          text: 'Templates',
          to: '/templates',
        },
        {
          icon: <FontDownload />,
          onClick: onClick,
          permission: 'canAccessFonts',
          text: 'Fonts',
          to: '/fonts',
        },
      ]}
      initialOpen={true}
    />
  );
};

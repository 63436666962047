import { CloudSync, Dvr, Settings } from '@mui/icons-material';
import { NavGroup } from '../NavGroup';

export const NetworkNav = ({ onClick }: { onClick: () => void }) => {
  return (
    <NavGroup
      title="Network Management"
      items={[
        {
          icon: <Settings />,
          onClick: onClick,
          permission: 'canManage',
          text: 'Settings',
          to: '/settings',
        },
        {
          icon: <Dvr />,
          onClick: onClick,
          permission: 'canManage',
          selected: 'channel',
          text: 'Channel Guides',
          to: '/channels',
        },
        {
          icon: <CloudSync />,
          onClick: onClick,
          permission: 'canAccessDataSources',
          text: 'Data Sources',
          to: '/data-sources',
        },
      ]}
    />
  );
};

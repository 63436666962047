import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext, type AppContext__Network } from '~/contexts';
import { useLink } from '~/hooks/link';

export interface PermissionFilterProps {
  permission: keyof AppContext__Network & `can${string}`;
}

export const PermissionFilter = ({ permission }: PermissionFilterProps) => {
  const { currentNetwork, transitioning } = useAppContext();
  const link = useLink();

  if (transitioning) return null; // prevent 404 from flashing while impersonate queries are loading
  if (currentNetwork[permission].value) return <Outlet />;

  return <Navigate replace to={link('/not-found')} />;
};

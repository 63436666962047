import {
  Box,
  Button,
  IconButton,
  Typography,
  styled,
  type SxProps,
  type Theme,
} from '@mui/material';
import type { ReactNode } from 'react';

export const StudioActionIconButton = styled(IconButton)(() => ({
  color: '#1b3a61', // theme.palette does not work here, unclear why
  '& svg': {
    fontSize: '20px',
  },
}));

export const StudioActionBarBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#f8f8f8',
  borderRadius: '8px',
  boxShadow: '0 4px 8px -8px rgba(64, 87, 109, 1)',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(0.15),
}));

export const StudioShapeButton = styled(Button)(() => ({
  flex: 1,
  '&:hover': {
    backgroundColor: 'inherit',
    opacity: 0.8,
  },
  '& svg': {
    fill: '#ccc',
    height: '120px',
    width: '120px',
  },
}));

export const StudioSidebarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRight: '1px solid rgb(63, 63, 63, .15)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  height: '100%',
}));

export const StudioSidebarInterior = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

export const StudioSideBySideContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
}));

export const StudioSideBySide = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
}));

export interface StudioSidebarTitleProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const StudioSidebarTitle = ({ children, sx }: StudioSidebarTitleProps) => (
  <Box sx={sx}>
    <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
      {children}
    </Typography>
  </Box>
);

export const StudioSidebarScrollable = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
}));

export const StudioSidebarScrollableInterior = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

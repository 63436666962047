import { Download, Edit, Folder, Layers, LayersClear } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemLink } from '~/components/link';
import { useLink } from '~/hooks/link';
import type { ContentFolderView__MediaItem } from '../ContentFolderView.generated';

export interface ContentMenuMediaItemProps {
  item: ContentFolderView__MediaItem;
  onMove: () => void;
  onOverlay: () => void;
  onRemoveOverlay: () => void;
}

export const ContentMenuMediaItem = ({
  item,
  onMove,
  onOverlay,
  onRemoveOverlay,
}: ContentMenuMediaItemProps) => {
  const link = useLink();
  return (
    <span>
      <MenuItemLink
        download={item.originalFile.originalFilename}
        to={item.originalFile.downloadUri}
      >
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        <ListItemText>Download</ListItemText>
      </MenuItemLink>

      <MenuItem disabled={!item.canUpdate.value} onClick={onMove}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText>Move to...</ListItemText>
      </MenuItem>

      {item.overlay ? (
        <MenuItem onClick={onRemoveOverlay}>
          <ListItemIcon>
            <LayersClear />
          </ListItemIcon>
          <ListItemText>Remove Overlay</ListItemText>
        </MenuItem>
      ) : (
        <MenuItem onClick={onOverlay}>
          <ListItemIcon>
            <Layers />
          </ListItemIcon>
          <ListItemText>Use as Overlay</ListItemText>
        </MenuItem>
      )}

      <MenuItemLink to={link(`/content/${item.contentFolderId}/media/${item.id}/details`)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItemLink>
    </span>
  );
};

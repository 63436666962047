import { Delete, DragIndicator } from '@mui/icons-material';
import { Box, Card, CardActionArea, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import type { DraggableProvided, DraggableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Circle, Image, Layer, Rect, RegularPolygon, Stage } from 'react-konva';
import useImage from 'use-image';
import { useNonce } from '~/hooks/csp';
import type { StudioItem } from '../../context';
import { useStudio } from '../../context';
import { type DesignEdit__Design as Design } from '../DesignEdit.generated';

const ITEM_PREVIEW_WIDTH = 75;
const ITEM_PREVIEW_HEIGHT = 40;

const StudioLayerItemText = ({ item }: { item: StudioItem }) => {
  return (
    <Tooltip title={String(item.text)}>
      <Typography variant="subtitle1" noWrap={true}>
        {item.text}
      </Typography>
    </Tooltip>
  );
};

const StudioLayerItemImage = ({
  design,
  item,
}: {
  design: Design | undefined;
  item: StudioItem;
}) => {
  const src = useMemo(
    () => design?.mediaItems.find((x) => x.id === item.__mediaItemId)?.thumbnails[0]?.uri ?? '',
    [design, item.__mediaItemId],
  );
  const [image] = useImage(src, 'anonymous');
  if (!src || !image) return null;
  return <Image image={image} {...item} x={0} y={0} />;
};

const StudioLayerItemContent = ({
  design,
  item,
}: {
  design: Design | undefined;
  item: StudioItem;
}) => {
  const itemWidth = item.width ?? ITEM_PREVIEW_WIDTH;
  const itemHeight = item.height ?? ITEM_PREVIEW_HEIGHT;

  const scaleFactor = Math.min(ITEM_PREVIEW_WIDTH / itemWidth, ITEM_PREVIEW_HEIGHT / itemHeight);

  const scaledItem = {
    ...item,
    x: 20, // default, specific types will override where needed
    y: (ITEM_PREVIEW_HEIGHT - itemHeight * scaleFactor) / 2,
    width: itemWidth * scaleFactor,
    height: itemHeight * scaleFactor,
    strokeWidth: (item.strokeWidth ?? 1) * scaleFactor,
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      height={ITEM_PREVIEW_HEIGHT}
      maxHeight={ITEM_PREVIEW_HEIGHT}
      maxWidth={ITEM_PREVIEW_WIDTH}
      width={ITEM_PREVIEW_WIDTH}
    >
      {scaledItem.type === 'text' ? (
        <StudioLayerItemText item={scaledItem} />
      ) : (
        <Stage width={ITEM_PREVIEW_WIDTH} height={ITEM_PREVIEW_HEIGHT}>
          <Layer>
            {scaledItem.type === 'circle' && (
              <Circle
                {...scaledItem}
                radius={(itemWidth / 2) * scaleFactor - ((item.strokeWidth ?? 1) * scaleFactor) / 2}
                y={20}
              />
            )}
            {scaledItem.type === 'rect' && <Rect {...scaledItem} x={0} />}
            {scaledItem.type === 'triangle' && (
              <RegularPolygon
                {...scaledItem}
                radius={item.radius * scaleFactor}
                y={item.rotation === 0 ? 24 : 20}
              />
            )}
            {(scaledItem.type === 'image' || scaledItem.type === 'video') && (
              <StudioLayerItemImage item={scaledItem} design={design} />
            )}
          </Layer>
        </Stage>
      )}
    </Box>
  );
};

const StudioLayerItem = ({
  design,
  index,
  item,
}: {
  design: Design | undefined;
  index: number;
  item: StudioItem;
}) => {
  const { deleteItems, selectItems, selectedItems } = useStudio();

  const rowId = `layer-${item.id}`;

  const isSelected = selectedItems.some((selected) => selected.id === item.id);

  return (
    <Draggable draggableId={rowId} key={item.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return (
          <Box
            key={rowId}
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              ...(snapshot.isDragging && {
                '& .MuiButtonBaseRoot': { backgroundColor: '#F5F5F5' },
                transform: provided.draggableProps.style?.transform
                  ? `translate(0px, ${provided.draggableProps.style.transform.split(',').pop()}`
                  : '',
              }),
            }}
          >
            <Card
              onClick={() => selectItems([item.id])}
              sx={{
                marginTop: 0.5,
                ...(isSelected && {
                  '& .MuiButtonBase-root': { backgroundColor: '#F5F5F5' },
                }),
              }}
              variant="outlined"
            >
              <CardActionArea
                component="div"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 0.5,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
                  <Box
                    sx={{
                      alignItems: 'flex-start',
                      display: 'flex',
                    }}
                    {...provided.dragHandleProps}
                  >
                    <Tooltip title="Reorder">
                      <DragIndicator color="secondary" />
                    </Tooltip>
                  </Box>

                  <StudioLayerItemContent design={design} item={item} />
                </Box>

                <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
                  <Tooltip title="Delete Layer">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteItems([item.id]);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        );
      }}
    </Draggable>
  );
};

export const StudioLayers = ({ design }: { design: Design | undefined }) => {
  const [_dragging, setDragging] = useState(false);

  const { items, reorderItems } = useStudio();

  const orderedItems = useMemo(() => {
    return items.slice().reverse();
  }, [items]);

  const nonce = useNonce();

  const onBeforeDragStart = () => setDragging(true);

  const onDragEnd = ({ destination, source }: DropResult) => {
    setDragging(false);

    if (destination == null || destination.index === source.index) return;

    const newOrderedItems = [...orderedItems];

    // Remove the moved item from the array
    const [movedItem] = newOrderedItems.splice(source.index, 1);

    // Insert the moved item at the new position
    newOrderedItems.splice(destination.index, 0, movedItem);

    reorderItems([...newOrderedItems.map((x) => x.id).reverse()]);
  };

  return (
    <Box
      borderLeft="1px solid rgb(63, 63, 63, .15)"
      display="flex"
      flexDirection="column"
      gap={1}
      height="100%"
      sx={{
        backgroundColor: '#ffffff',
      }}
    >
      <Stack height="100%">
        <Box
          flexGrow={1}
          paddingBottom={1}
          paddingTop={0.5}
          paddingX={1}
          sx={{ overflowY: 'auto' }}
        >
          <DragDropContext
            nonce={nonce}
            onBeforeDragStart={onBeforeDragStart}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="studio-layers">
              {(droppableProvided) => (
                <Box
                  ref={(ref: HTMLElement | null) => {
                    droppableProvided.innerRef(ref);
                  }}
                >
                  {orderedItems.map((item, index) => {
                    return (
                      <StudioLayerItem design={design} index={index} item={item} key={index} />
                    );
                  })}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Stack>
    </Box>
  );
};

import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { MediaItemDetailsDocument, MediaItemInfo } from '~/components/media';
import { useAppContext } from '~/contexts';

export const MediaItemDetails = () => {
  const params = useParams<{ mediaItemId: string }>();
  const mediaItemId = parseInt(params.mediaItemId ?? '');
  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(MediaItemDetailsDocument, {
    fetchPolicy: 'cache-first',
    variables: { id: mediaItemId, networkId: currentNetwork.id },
  });

  if ((!loading && !data) || error) return <Navigate to="/not-found" />;

  const mediaItem = data?.network?.mediaItem;

  return (
    <LoadingPane in={loading && !data}>
      {mediaItem && (
        <MediaItemInfo mediaItem={mediaItem} displayTags={true} displayUseOriginal={true} />
      )}
    </LoadingPane>
  );
};

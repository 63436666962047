import { Box, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteShow } from '~/api/shows';
import { DeleteButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import { formatDate } from '~/lib/datetime';
import { pluralize } from '~/lib/string';
import type { ShowActions__Show } from './actions.generated';

/* GraphQL */ `#graphql
fragment ShowActions__Show on Show {
  id
  canDestroy {
    ...AuthorizationResult
  }
  canUpdate {
    ...AuthorizationResult
  }
  eventDeviceGroups {
    id
    deviceGroup {
      id
      name
      deviceCount
    }
    event {
      id
      name
      start
    }
    show {
      id
      name
    }
  }
  origin {
    id
  }
}
`;

export interface ActionsProps {
  show: ShowActions__Show;
}

export const Actions = ({ show }: ActionsProps) => {
  const navigate = useNavigate();

  const [confirm, confirmProps] = useConfirmDialog();

  const [deleteShow] = useDeleteShow();

  const handleDelete = useCallback(async () => {
    if (!(await confirm())) return;
    await deleteShow({ variables: { showIds: [show.id] } });
    navigate('../..');
  }, [confirm, deleteShow, navigate, show.id]);

  const { event, deviceGroup } = show.eventDeviceGroups.find((x) => x.show.id === show.id) || {};

  const prompt = useMemo(() => {
    if (!show.canDestroy.value) return null;
    if (show.origin && event && deviceGroup)
      return (
        <>
          This show is specific to the <strong>{event.name}</strong> event happening on{' '}
          <strong>{formatDate(event.start, { format: DateTime.DATETIME_MED })}</strong>. Deleting it
          will change what is playing on <strong>{deviceGroup.deviceCount}</strong>{' '}
          {pluralize('device', deviceGroup.deviceCount)} in the <strong>{deviceGroup.name}</strong>{' '}
          device group, which will revert to the show assigned to the device group for the event.
        </>
      );
    return 'Deleting this show will remove it from devices where it is being used.';
  }, [deviceGroup, event, show]);

  if (!show.canUpdate.value) return <Box />;

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title={show.canDestroy.reasons?.fullMessages.join(', ')}>
          <span>
            <DeleteButton disabled={!show.canDestroy.value} onClick={handleDelete} />
          </span>
        </Tooltip>
      </Box>
      <ConfirmDialog
        {...confirmProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={prompt}
        title="Delete Show"
      />
    </>
  );
};

import { useFragment } from '@apollo/client';
import { Typography } from '@mui/material';
import { useMatch, useParams } from 'react-router-dom';
import { Link, type LinkProps } from '~/components/link';
import { useLink } from '~/hooks/link';
import { EventTypeBreadcrumb__EventType } from './breadcrumbs.generated';

/* GraphQL */ `#graphql
fragment EventTypeBreadcrumb__EventType on EventType {
  id
  name
}
`;

export const EventTypesBreadcrumb = (props: Omit<LinkProps, 'to'>) => {
  const link = useLink();
  const match = useMatch('/networks/:networkId/settings/event-types');

  return match == null ? (
    <Link color="inherit" to={link('/event-types')} {...props}>
      Event Types
    </Link>
  ) : (
    <Typography>Event Types</Typography>
  );
};

export const EventTypeBreadcrumb = ({ ...props }: Omit<LinkProps, 'to'>) => {
  const { eventTypeId } = useParams<{ eventTypeId: string }>();

  const { complete, data } = useFragment({
    fragment: EventTypeBreadcrumb__EventType,
    fragmentName: 'EventTypeBreadcrumb__EventType',
    from: { __typename: 'EventType', id: eventTypeId },
  });

  const link = useLink();

  const match = useMatch('/networks/:networkId/settings/event-types/:eventTypeId/details');

  if (!complete) return null;

  return match == null ? (
    <Link color="inherit" to={link(`/event-types/${eventTypeId}`)} {...props}>
      {data.name}
    </Link>
  ) : (
    <Typography>{data.name}</Typography>
  );
};
export interface EventTypeListBreadcrumbProps extends Omit<LinkProps, 'to'> {
  segmentLink: string;
  segmentTitle: string;
}

export const EventTypeListBreadcrumb = ({
  segmentLink,
  segmentTitle,
  ...props
}: EventTypeListBreadcrumbProps) => {
  const { eventTypeId } = useParams<{ eventTypeId: string }>();
  const link = useLink();

  const match = useMatch(`/networks/:networkId/settings/event-types/:eventTypeId/${segmentLink}`);

  return match == null ? (
    <Link color="inherit" to={link(`/event-types/${eventTypeId}/${segmentLink}`)} {...props}>
      {segmentTitle}
    </Link>
  ) : (
    <Typography>{segmentTitle}</Typography>
  );
};

import { useQuery } from '@apollo/client';
import { DeviceHub } from '@mui/icons-material';
import { Box, Button, TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { number, object, string } from 'yup';
import { useDeleteDeviceGroup, useUpdateDeviceGroup } from '~/api/device-groups';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { ScrollableRouterTabs } from '~/components/RouterTabs';
import type { ShowAutocomplete__Show as Show } from '~/components/ShowAutocomplete';
import { ShowAutocomplete } from '~/components/ShowAutocomplete';
import { DeleteButton, SaveButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { DetailTextArea, DetailTextField } from '~/components/forms/details';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import {
  SettingDescription,
  SettingFieldRequired,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { Timestamps } from '~/components/timestamps';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { toDataUri } from '~/lib/file';
import { NetworkDeviceGroupSettingsDocument } from '../queries/settings.generated';
import { tabs } from './lib/tabs';

const validationSchema = object({
  name: string().required(),
  description: string().optional(),
  defaultShowId: number().nullable(),
  thumbnailUri: string().optional(),
});

export const DeviceGroupSettings = () => {
  const { currentNetwork, currentUser } = useAppContext();

  const navigate = useNavigate();

  const link = useLink();

  const params = useParams<{ deviceGroupId: string }>();

  const deviceGroupId = Number(params.deviceGroupId);

  const { data, loading, error } = useQuery(NetworkDeviceGroupSettingsDocument, {
    variables: { networkId: currentNetwork.id, deviceGroupId },
  });

  const [deleteDeviceGroup] = useDeleteDeviceGroup();
  const [updateDeviceGroup] = useUpdateDeviceGroup();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.network?.deviceGroup.name ?? '',
      description: data?.network?.deviceGroup.description ?? '',
      defaultShowId: data?.network?.deviceGroup.defaultShow?.id,
      thumbnailUri: data?.network?.deviceGroup.thumbnailFile?.uri ?? '',
    },
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await updateDeviceGroup({
        variables: {
          patch: {
            ...newValues,
            defaultShowId: newValues.defaultShowId === -1 ? null : newValues.defaultShowId,
          },
          id: deviceGroupId,
        },
      });
    },
    validateOnBlur: true,
    validationSchema,
  });

  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  const handleShowChange = useCallback(
    async (newValue: Show | null) => {
      await formik.setFieldValue('defaultShowId', newValue?.id ?? -1);
    },
    [formik],
  );

  const thumbnailSrc = formik.values.thumbnailUri.trim();

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <>
        <Toolbar
          actions={
            <SaveButton
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              type="submit"
              form="update-device-group"
            />
          }
          breadcrumbsLabel={<RouterBreadcrumbs />}
          titleIcon={<DeviceHub />}
          titleText={data?.network?.deviceGroup.name}
        />

        <ScrollableRouterTabs current="Details" tabs={tabs} />

        <PageContainer>
          <Box
            component="form"
            id="update-device-group"
            onSubmit={formik.handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <div>
              <SettingsHead>General</SettingsHead>
              <SettingsBody>
                <SettingsData>
                  <SettingLabelWrapper>
                    <SettingName>
                      Name<SettingFieldRequired>*</SettingFieldRequired>
                    </SettingName>
                    <SettingDescription>Name of the device group</SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <DetailTextField
                      autoFocus
                      disabled={formik.isSubmitting}
                      error={formik.touched.name && !!formik.errors.name}
                      fullWidth
                      helperText={(formik.touched.name && formik.errors.name) || ' '}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                  </SettingInputWrapper>
                </SettingsData>
                <SettingsData>
                  <SettingLabelWrapper>
                    <SettingName>Description</SettingName>
                    <SettingDescription>Description of the device group</SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <DetailTextArea
                      multiline
                      minRows={2}
                      maxRows={2}
                      disabled={formik.isSubmitting}
                      error={formik.touched.description && !!formik.errors.description}
                      fullWidth
                      helperText={(formik.touched.description && formik.errors.description) || ' '}
                      name="description"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                  </SettingInputWrapper>
                </SettingsData>
                <SettingsData>
                  <SettingLabelWrapper>
                    <SettingName>Thumbnail</SettingName>
                    <SettingDescription>Thumbnail of the device group</SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <Box display="flex" flexDirection="row" gap={2}>
                      {thumbnailSrc && (
                        <Box display="flex" style={{ height: '4rem' }}>
                          <img src={thumbnailSrc} />
                        </Box>
                      )}
                      <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
                        <input {...getInputProps()} />
                        <div>
                          <Button onClick={selectFile} variant="outlined">
                            Upload Thumbnail
                          </Button>
                        </div>
                        <Box display="flex" flexDirection="column">
                          <span>Or, enter a URL:</span>
                          <TextField
                            fullWidth
                            name="thumbnailUri"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            size="small"
                            value={
                              formik.values.thumbnailUri.startsWith('http')
                                ? formik.values.thumbnailUri
                                : ''
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </SettingInputWrapper>
                </SettingsData>
                <SettingsData className="last">
                  <SettingLabelWrapper>
                    <SettingName>Default Show</SettingName>
                    <SettingDescription>Default show for the device group</SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <ShowAutocomplete
                      onChange={handleShowChange}
                      value={data?.network?.deviceGroup.defaultShow ?? null}
                      sx={{
                        display: 'inline-flex', // hackeration so label is centered since it's set to baseline
                      }}
                    />
                  </SettingInputWrapper>
                </SettingsData>
              </SettingsBody>
            </div>

            {currentUser.admin && (
              <Timestamps
                thing="device group"
                createdAt={data?.network?.deviceGroup.createdAt ?? ''}
                updatedAt={data?.network?.deviceGroup.updatedAt ?? ''}
              />
            )}

            <div>
              <SettingsHead>Remove Device Group</SettingsHead>
              <SettingsBody>
                <SettingsData className="last">
                  <SettingLabelWrapper>
                    <SettingName></SettingName>
                    <SettingDescription>
                      <span>
                        Deleting this device group will remove it from the system. &nbsp;&nbsp;
                        <b>This action is permanent.</b>
                      </span>
                    </SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <Tooltip
                      title={
                        data?.network?.deviceGroup.canDestroy.value
                          ? 'Delete Device Group'
                          : data?.network?.deviceGroup.canDestroy.reasons?.fullMessages.join(
                              ', ',
                            ) || 'Cannot Delete Group'
                      }
                    >
                      <span>
                        <DeleteButton
                          disabled={!data?.network?.deviceGroup.canDestroy.value}
                          onClick={async () => {
                            if (!(await confirmDelete())) return;
                            await deleteDeviceGroup({
                              variables: { id: deviceGroupId },
                            });
                            navigate(link('/device-groups'), { replace: true });
                          }}
                        >
                          Delete Device Group
                        </DeleteButton>
                      </span>
                    </Tooltip>
                  </SettingInputWrapper>
                </SettingsData>
              </SettingsBody>
            </div>
          </Box>
        </PageContainer>
        <ConfirmDialog
          {...confirmDeleteProps}
          confirm="Permanently Delete"
          deleteConfirm
          prompt={
            <span>
              Deleting this Device Group will permanently remove it from the system.{' '}
              <b>This operation may not be undone.</b>
            </span>
          }
          title="Delete Device Group"
        />
      </>
    </LoadingPane>
  );
};

import { useMutation, useQuery } from '@apollo/client';
import { Hub } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { Duration } from 'luxon';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { number, object, string } from 'yup';
import { SaveButton } from '~/components/button';
import { DetailSelect, DetailTextField } from '~/components/forms/details';
import { NumberInput } from '~/components/inputs/NumberInput/NumberInput';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { FormLayout } from '~/components/settings';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { NetworkSettingsDocument, UpdateNetworkDocument } from '~/generated/graphql';

const validationSchema = object({
  name: string().required(),
  defaultTimeZone: string().required(),
  defaultImageDuration: number()
    .required()
    .min(5)
    .max(60 * 60),
});

export const NetworkSettings = () => {
  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(NetworkSettingsDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [updateNetwork] = useMutation(UpdateNetworkDocument);

  const initialValues = useMemo(
    () => ({
      name: data?.network?.name ?? '',
      defaultImageDuration: data?.network?.defaultImageDuration
        ? Duration.fromISO(data.network.defaultImageDuration).as('seconds')
        : null,
      defaultTimeZone: data?.network?.defaultTimeZone ?? '',
    }),
    [data?.network],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await updateNetwork({
        variables: {
          networkId: currentNetwork.id,
          patch: {
            ...newValues,
            defaultImageDuration: Duration.fromObject({
              seconds: newValues.defaultImageDuration,
            }).toISO(),
          },
        },
      });
    },
    validateOnMount: true,
    validationSchema,
  });

  const generalSettings = useMemo(
    () => [
      {
        heading: 'Name',
        subHeading: 'A human-friendly name for the network',
        dataField: (
          <DetailTextField
            autoFocus
            disabled={formik.isSubmitting || !data?.network}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
          />
        ),
      },
      {
        heading: 'Default Time Zone',
        subHeading: 'Time zone where devices within this network are located',
        dataField: (
          <DetailSelect
            disabled={formik.isSubmitting || !data?.network}
            name="defaultTimeZone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.defaultTimeZone}
          >
            {data?.timeZones.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Default Image Duration',
        subHeading: 'Default duration used for images when adding to playlists',
        dataField: (
          <NumberInput
            disabled={formik.isSubmitting || !data?.network}
            onBlur={formik.handleBlur}
            onChange={(_, val) => formik.setFieldValue('defaultImageDuration', val)}
            value={formik.values.defaultImageDuration}
            helperText="Between 5 and 3600 seconds"
            min={5}
            max={60 * 60}
          />
        ),
      },
    ],
    [data?.network, data?.timeZones, formik],
  );

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  const saveButton = data?.network?.canUpdate.value && (
    <SaveButton
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      type="submit"
      form="update-network"
    />
  );

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <Toolbar actions={saveButton} titleIcon={<Hub />} titleText="Network" />
      <PageContainer>
        <Box component="form" id="update-network" onSubmit={formik.handleSubmit}>
          <FormLayout formHeading="General" formData={generalSettings} />
        </Box>
      </PageContainer>
    </LoadingPane>
  );
};

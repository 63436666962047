import { AdminPanelSettings, Dashboard, Menu, MenuOpen } from '@mui/icons-material';
import { Box, IconButton, styled, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import type { CSSObject, Theme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import logo from '~/images/fc-logo-alt.svg';
import { useResponsive } from '~/lib/responsive';
import { useSideNav } from '../context';
import {
  ContentNav,
  DeviceNav,
  NetworkNav,
  ReportingNav,
  SchedulingNav,
  UserNav,
} from './NavGroups';
import { NavItemLink } from './NavItems';
import { NetworkButton } from './NetworkButton';
import { UserButton } from './UserButton';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    zIndex: '1200',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    width: 0, // hide entirely
    position: 'fixed',
    zIndex: '1200',
  },
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export const DrawerHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  ...(!open && {
    justifyContent: 'center',
  }),
}));

export const SideNav = () => {
  const { currentUser } = useAppContext();

  const { isSmDown } = useResponsive();

  const theme = useTheme();

  const { open, toggleDrawer } = useSideNav();

  const location = useLocation();

  const link = useLink();

  const handleClickAway = () => {
    isSmDown && toggleDrawer();
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'primary.main',
          border: 0,
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DrawerHeader>
        {open && (
          <Link sx={{ flexBasis: '75%' }} to={link('/dashboard')}>
            <img
              src={logo}
              style={{
                marginTop: theme.spacing(0.5),
              }}
            />
          </Link>
        )}
        <IconButton
          sx={{
            color: 'common.white',
            ...(open && {
              paddingRight: 0,
            }),
          }}
          onClick={toggleDrawer}
        >
          {open ? <Menu /> : <MenuOpen />}
        </IconButton>
      </DrawerHeader>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ paddingX: theme.spacing(1) }}>
          <NetworkButton />

          {!isSmDown && currentUser.admin && (
            <NavItemLink icon={<AdminPanelSettings />} text="Administrate" href="/admin" />
          )}

          <NavItemLink
            icon={<Dashboard />}
            text="Dashboard"
            to="/dashboard"
            selected={location.pathname.includes('dashboard')}
            onClick={handleClickAway}
          />
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingX: theme.spacing(1) }}>
          <DeviceNav onClick={handleClickAway} />
          <ContentNav onClick={handleClickAway} />
          <SchedulingNav onClick={handleClickAway} />
          <ReportingNav onClick={handleClickAway} />
          <UserNav onClick={handleClickAway} />
          <NetworkNav onClick={handleClickAway} />
        </Box>
      </Box>
      <Box
        height="55px"
        width="100%"
        sx={{
          backgroundColor: 'primary.main',
        }}
      >
        <Box sx={{ marginX: theme.spacing(1) }}>
          <UserButton />
        </Box>
      </Box>
    </Drawer>
  );
};

import { useQuery } from '@apollo/client';
import { AddCircle, MovieCreation, Sell } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { FilterSidebar, FilterToggle } from '~/components/Filter';
import {
  ListActions,
  ListContainer,
  ListContent,
  ListHeader,
  ListPageContainer,
} from '~/components/PageLayout';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { CreateShowWizard, useCreateShowWizard } from '../components/create-show-wizard';
import { ShowListDocument, type ShowList__Show as Show } from '../queries/queries.generated';
import { BulkActions, Table, TagsDialog } from './components/';
import { FilterAccordion } from './components/FilterAccordion';
import { FilterChips } from './components/FilterChips';
import { BulkTagDialog } from './components/bulk-tag-dialog';
import { useFilters, useSelectedTagParams } from './lib';

export const ShowList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [showManageTags, setManageTags] = useState(false);
  const showWizard = useCreateShowWizard();
  const [selectedShow, setSelectedShow] = useState<Show>();

  const tags = useSelectedTagParams();

  const { data, loading } = useQuery(ShowListDocument, {
    variables: { currentNetworkId: currentNetwork.id, page, perPage, search, tags },
  });

  const totalCount = useTotalCount(data?.network?.shows.pageInfo.nodesCount);
  const shows = data?.network?.shows.nodes ?? [];

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  useEffect(() => setSelectedIds([]), [setSelectedIds]);

  const canDestroySelectedShows = selectedIds.every((selectedId) => {
    const show = shows.find((show) => show.id === selectedId);
    return show?.canDestroy.value;
  });

  const showTags = data?.network?.showTags.map((x) => x.name);
  const filterCounts = data?.network?.showFilterInfo;

  const { filter, clearFilter, setFilterItems, toggleAccordion, toggleItem, togglePanel } =
    useFilters(showTags);

  const filtersActive = useMemo(() => tags.length > 0, [tags]);

  return (
    <>
      <Helmet title="Shows" />
      <Toolbar
        titleIcon={<MovieCreation />}
        titleText="Shows"
        actions={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => setManageTags(!showManageTags)}
              startIcon={<Sell />}
              variant="outlined"
            >
              Manage Tags
            </Button>
            <Button
              color="primary"
              disabled={!currentNetwork.canCreateShows.value}
              onClick={showWizard.open}
              startIcon={<AddCircle />}
              variant="contained"
            >
              New Show
            </Button>
          </Box>
        }
      ></Toolbar>
      <ListPageContainer>
        <FilterSidebar open={filter.panelOpen}>
          <FilterAccordion
            clearFilter={clearFilter}
            filter={filter}
            filterCounts={filterCounts}
            toggleAccordion={toggleAccordion}
            updateFilter={setFilterItems}
          />
        </FilterSidebar>
        <ListContainer filterOpen={filter.panelOpen}>
          <ListHeader>
            <ListActions>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FilterToggle active={filtersActive} onToggle={togglePanel} />
                <SearchBar
                  onChange={(value) =>
                    setSearchParams((params) => {
                      params.set('search', value);
                      params.set('page', '1');
                      return params;
                    })
                  }
                  placeholder="Search Shows"
                  search={search}
                />
              </Box>
              <Box sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center' }}>
                {selectedIds.length > 0 ? (
                  <BulkActions
                    selectedIds={selectedIds}
                    onTagContent={() => setTagContentDialogOpen(true)}
                    canDestroySelectedShows={canDestroySelectedShows}
                  />
                ) : null}
                <Count selectedCount={selectedIds.length} totalCount={totalCount} thing="show" />
              </Box>
            </ListActions>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  visibility: filtersActive ? 'visible' : 'hidden',
                }}
              >
                <FilterChips items={filter.items || []} toggleItem={toggleItem} />
              </Box>
            </Box>
          </ListHeader>
          <ListContent>
            <Table
              loading={loading}
              shows={data?.network?.shows.nodes ?? []}
              selectedShow={selectedShow}
              onCheck={setSelectedIds}
              onSelectedShow={setSelectedShow}
              selectedIds={selectedIds}
              totalCount={totalCount}
            />
          </ListContent>
          {showManageTags && <TagsDialog onClose={() => setManageTags(false)} open />}

          {tagContentDialogOpen && (
            <BulkTagDialog
              showIds={selectedIds as string[]}
              showTags={data?.network?.showTags.map((x) => x.name) || []}
              onClose={() => setTagContentDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              tags={tags}
            />
          )}
        </ListContainer>
      </ListPageContainer>
      <CreateShowWizard {...showWizard.props} />
    </>
  );
};

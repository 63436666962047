import { useCallback } from 'react';
import { Link } from '~/components/link';
import { useLink } from '~/hooks/link';
import { exhausted } from '~/lib/assert';

interface Entities {
  ContentItem: { contentFolderId?: number | null };
  DataSource: unknown;
  Design: unknown;
  Device: unknown;
  DeviceGroupNew: unknown;
  MediaItem: { contentFolderId: number };
  Playlist: unknown;
  Show: unknown;
  User: unknown;
}

type NamedEntity = {
  [K in keyof Entities]: { __typename: K; id: number; name: string } & Entities[K];
}[keyof Entities];

const pathFor = ({ id, ...entity }: NamedEntity): string => {
  if (entity.__typename === 'ContentItem')
    return `/content/${entity.contentFolderId}/apps/${id}/settings`;
  if (entity.__typename === 'DataSource') return `/data-sources/${id}`;
  if (entity.__typename === 'Design') return `/studio/${id}`;
  if (entity.__typename === 'Device') return `/devices/${id}`;
  if (entity.__typename === 'DeviceGroupNew') return `/device-groups/${id}`;
  if (entity.__typename === 'MediaItem')
    return `/content/${entity.contentFolderId}/media/${id}/details`;
  if (entity.__typename === 'Playlist') return `/playlists/${id}`;
  if (entity.__typename === 'Show') return `/shows/${id}`;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (entity.__typename === 'User') return `/users/${id}`;
  return exhausted(entity, 'pathFor: unknown entity');
};

interface AuthorizedLinkProps {
  authorized?: boolean;
  entity: NamedEntity | undefined | null;
  fallback?: string;
}

export const AuthorizedLink = ({
  authorized = false,
  entity,
  fallback = '--',
}: AuthorizedLinkProps) => {
  const link = useLink();

  const handleClick = useCallback((event: React.MouseEvent) => event.stopPropagation(), []); // Prevents table row click propagation

  return entity && authorized ? (
    <Link to={link(pathFor(entity))} onClick={handleClick}>
      {entity.name}
    </Link>
  ) : (
    <span>{entity?.name ?? fallback}</span>
  );
};

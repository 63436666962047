import { Typography } from '@mui/material';
import { DeviceLogo, DeviceStatus, DeviceVersion } from '~/components/devices';
import { ActiveShowName } from '~/components/devices/ActiveShowName/ActiveShowName';
import { DeviceDisplayIndicator } from '~/components/devices/device-indicators/device-indicators';
import { AuthorizedLink } from '~/components/links';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { useDevice } from '../context';
import { ValueWrapper } from '../lib';

export const Details = () => {
  const { currentNetwork } = useAppContext();
  const { device } = useDevice();

  return (
    <>
      <SettingsHead>Quick Info</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          <MetaItem className="first">
            <ListRowHeadingValue
              heading="Connectivity"
              value={<DeviceStatus device={device} displayText={true} />}
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Display"
              value={
                <ValueWrapper>
                  <DeviceDisplayIndicator device={device} />
                  <Typography
                    variant="h6"
                    sx={{ color: device.displayOn ? 'success.main' : 'primary.dark' }}
                  >
                    {device.displayOn === true ? 'On' : device.displayOn === false ? 'Off' : ''}
                  </Typography>
                </ValueWrapper>
              }
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Now Playing"
              value={<ActiveShowName activeShow={device.activeShow} />}
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Type"
              value={
                <ValueWrapper sx={{ alignItems: 'center' }}>
                  <DeviceLogo kind={device.kind} />
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {device.kind.toLowerCase()}
                  </Typography>
                </ValueWrapper>
              }
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Arch" value={device.arch} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Group"
              value={
                <AuthorizedLink authorized={currentNetwork.canManage.value} entity={device.group} />
              }
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="IP Address" value={device.ipAddress} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="MAC Address" value={device.macAddress} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Version" value={<DeviceVersion device={device} />} />
          </MetaItem>
          <MetaItem className="last">
            <ListRowHeadingValue heading="Model" value={device.model ?? '--'} />
          </MetaItem>
        </SettingsData>
      </SettingsBody>
    </>
  );
};

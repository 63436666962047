import { Box, styled } from '@mui/material';

export const DurationContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  '&.readonly': {
    color: theme.palette.text.secondary,
    cursor: 'not-allowed',
  },
  width: '100%',
}));

export const ThumbnailContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export const PlaylistContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderTop: '1px solid',
  borderColor: '#dedcdc',
}));

export const PlaylistHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export const NoContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'center',
}));

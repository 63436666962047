import { useLazyQuery, useQuery } from '@apollo/client';
import { Assessment, CheckBox, CheckBoxOutlineBlank, Clear } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Paper,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { type DateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { array, date, number, object } from 'yup';
import { useGenerateReport } from '~/api/reporting/generate';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { assert } from '~/lib/assert';
import { pluralize } from '~/lib/string';
import { ReportDownloadDialog } from './components/download-dialog';
import {
  ReportingEventTypeEventsDocument,
  ReportingEventTypesDocument,
  type ReportingEventTypes__DeviceGroup as DeviceGroup,
  type ReportingEventTypes__EventType as EventType,
} from './reporting.generated';

const validationSchema = object({
  deviceGroups: array(object().shape({ id: number().required() }))
    .min(1)
    .required(),
  eventType: object().shape({ id: number().min(1).required() }),
  from: date().required(),
  to: date().required(),
});

export const ReportingForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  width: '75%',

  '& label': {
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,

    '.label': {
      flexBasis: '20%',
      flexShrink: 0,
    },

    '.required::after': {
      content: '" *"',
      color: theme.palette.error.dark,
    },
  },
}));

interface ReportingFormValues {
  deviceGroups: DeviceGroup[];
  eventType: EventType | null;
  from: DateTime | null;
  to: DateTime | null;
}

export const Reporting = () => {
  const { currentNetwork } = useAppContext();

  const [generate] = useGenerateReport();

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  const { data: eventTypeData, loading } = useQuery(ReportingEventTypesDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [eventsQuery] = useLazyQuery(ReportingEventTypeEventsDocument);

  const eventTypes = eventTypeData?.reporting?.eventTypes;

  const allDeviceGroups = useMemo(() => {
    const allEntry = {
      __typename: 'DeviceGroupNew' as const,
      id: -1,
      name: 'All Device Groups',
      deviceCount: eventTypeData?.reporting?.deviceCount || 0,
    };
    const otherEntries = eventTypeData?.reporting?.deviceGroups ?? [];
    return [allEntry, ...otherEntries];
  }, [eventTypeData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deviceGroups: [],
      eventType: null,
      from: null,
      to: null,
    },
    onSubmit: async (input: ReportingFormValues) => {
      assert(input.eventType !== null, 'Event Type is null');
      const { data } = await generate({
        variables: {
          input: {
            deviceGroupIds: input.deviceGroups.some((group) => group.id === -1)
              ? null
              : input.deviceGroups.filter((x) => x.id !== -1).map((x) => x.id), // filter out -1 jic
            eventTypeId: input.eventType.id,
            from: input.from?.toISO() ?? '',
            to: input.to?.toISO() ?? '',
          },
        },
      });

      const url = data?.generateReport?.url;
      if (url) setDownloadUrl(url);
    },
    validateOnBlur: true,
    validationSchema,
  });

  const handleEventTypeChange = async (value: EventType | null) => {
    if (!value) return;

    // Get list of events for selected type
    const { data } = await eventsQuery({
      variables: {
        networkId: currentNetwork.id,
        eventTypeId: value.id,
      },
    });

    const events = data?.reporting?.eventType.events;
    if (!events) return; // shouldn't happen

    // Grab the first and last event to use as the initial range
    const firstEvent = events.at(0);
    assert(firstEvent != null, 'no first event');
    const lastEvent = events.at(-1);
    assert(lastEvent != null, 'no last event');

    void formik.setFieldValue('eventType', value);
    void formik.setFieldValue('from', DateTime.fromISO(firstEvent.start).startOf('day'));
    void formik.setFieldValue('to', DateTime.fromISO(lastEvent.start).startOf('day'));
  };

  const handleDateRangeChange = (value: DateRange<DateTime>) => {
    void formik.setFieldValue('from', value[0]);
    void formik.setFieldValue('to', value[1]);
  };

  const handleDeviceGroupChange = (value: readonly DeviceGroup[]) => {
    void formik.setFieldValue('deviceGroups', value);
  };

  return (
    <>
      <LoadingPane in={loading && !eventTypeData} size={80} thickness={4}>
        <Toolbar
          breadcrumbsLabel={<RouterBreadcrumbs />}
          titleIcon={<Assessment />}
          titleText="Playout Reporting"
        />
        <PageContainer>
          <Container maxWidth="md">
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 4,
                gap: 4,
              }}
            >
              <Typography variant="subtitle1">
                Select an event type, date range, and device groups to generate a playout report.
              </Typography>
              <ReportingForm id="report-form" onSubmit={formik.handleSubmit}>
                <label>
                  <span className={`label required`}>Event Type</span>
                  <Autocomplete
                    autoHighlight
                    getOptionDisabled={(option) => option.eventCount === 0}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText="No Event Types"
                    options={eventTypes ?? []}
                    onChange={(_event, newValue) => handleEventTypeChange(newValue)}
                    renderInput={(params) => <TextField {...params} label="Select an Event Type" />}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            mr: 1,
                          }}
                        >
                          {option.iconFile && (
                            <img src={option.iconFile.uri} style={{ width: '50px' }} />
                          )}
                        </Box>
                        {`${option.name} (${option.eventCount} ${pluralize(
                          'event',
                          option.eventCount,
                        )})`}
                      </Box>
                    )}
                    value={formik.values.eventType}
                  />
                </label>
                <label>
                  <span className={`label required`}>Date Range</span>
                  <DateRangePicker
                    localeText={{ start: 'From', end: 'To' }}
                    onChange={handleDateRangeChange}
                    sx={{ width: '100%' }}
                    value={[formik.values.from, formik.values.to]}
                  />
                </label>
                <label>
                  <span className={`label required`}>Device Group</span>
                  <Autocomplete
                    autoHighlight
                    disableCloseOnSelect
                    getOptionDisabled={
                      (option) =>
                        option.deviceCount === 0 || // disable groups w/o devices
                        (formik.values.deviceGroups.at(0)?.id === -1 && option.id !== -1) || // disable other groups if all is selected
                        (formik.values.deviceGroups.length > 0 &&
                          formik.values.deviceGroups.at(0)?.id !== -1 &&
                          option.id === -1) // disable all option if others are selected and the only selected option is not the all option
                    }
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText="No Device Groups"
                    limitTags={2}
                    multiple
                    options={allDeviceGroups}
                    onChange={(_event, newValue) => handleDeviceGroupChange(newValue)}
                    sx={{
                      minHeight: '55px',
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select a Device Group" />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <Box component="li" {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option.name} (${option.deviceCount} ${pluralize(
                          'device',
                          option.deviceCount,
                        )})`}
                      </Box>
                    )}
                    value={formik.values.deviceGroups}
                  />
                </label>
              </ReportingForm>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Button
                  disabled={formik.isSubmitting || !formik.dirty}
                  onClick={() => formik.resetForm()}
                  size="large"
                  startIcon={<Clear />}
                  variant="outlined"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<Assessment />}
                  variant="contained"
                  form="report-form"
                  type="submit"
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  Generate Report
                </Button>
              </Box>
            </Paper>
          </Container>
        </PageContainer>
      </LoadingPane>
      <ReportDownloadDialog
        close={() => setDownloadUrl(null)}
        from={formik.values.from}
        reportUrl={downloadUrl}
        to={formik.values.to}
      />
    </>
  );
};

import { useFragment } from '@apollo/client';
import { Typography } from '@mui/material';
import { useMatch, useParams } from 'react-router-dom';
import { Link, type LinkProps } from '~/components/link';
import { useLink } from '~/hooks/link';
import { TvBrandBreadcrumb__TvBrand } from './breadcrumbs.generated';

/* GraphQL */ `#graphql
fragment TvBrandBreadcrumb__TvBrand on TVBrand {
  id
  name
}
`;

export const TVBrandsBreadcrumb = (props: Omit<LinkProps, 'to'>) => {
  const link = useLink();
  const match = useMatch('/networks/:networkId/settings/tv-brands');

  return match == null ? (
    <Link color="inherit" to={link('/tv-brands')} {...props}>
      TV Brands
    </Link>
  ) : (
    <Typography>TV Brands</Typography>
  );
};

export const TVBrandBreadcrumb = ({ ...props }: Omit<LinkProps, 'to'>) => {
  const { tvBrandId } = useParams<{ tvBrandId: string }>();

  const { complete, data } = useFragment({
    fragment: TvBrandBreadcrumb__TvBrand,
    fragmentName: 'TvBrandBreadcrumb__TvBrand',
    from: { __typename: 'TVBrand', id: tvBrandId },
  });

  const link = useLink();

  const match = useMatch('/networks/:networkId/settings/tv-brands/:tvBrandId/details');

  if (!complete) return null;

  return match == null ? (
    <Link color="inherit" to={link(`/tv-brands/${tvBrandId}`)} {...props}>
      {data.name}
    </Link>
  ) : (
    <Typography>{data.name}</Typography>
  );
};

export interface TVBrandListBreadcrumbProps extends Omit<LinkProps, 'to'> {
  segmentLink: string;
  segmentTitle: string;
}

export const TVBrandListBreadcrumb = ({
  segmentLink,
  segmentTitle,
  ...props
}: TVBrandListBreadcrumbProps) => {
  const { tvBrandId } = useParams<{ tvBrandId: string }>();

  const link = useLink();

  const match = useMatch(`/networks/:networkId/settings/tv-brands/:tvBrandId/${segmentLink}`);

  return match == null ? (
    <Link color="inherit" to={link(`/tv-brands/${tvBrandId}/${segmentLink}`)} {...props}>
      {segmentTitle}
    </Link>
  ) : (
    <Typography>{segmentTitle}</Typography>
  );
};

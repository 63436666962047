import { Category, People } from '@mui/icons-material';
import type { AuditLogsList__FilterCount } from '~/components/AuditLogs/queries/list.generated';
import {
  FilterAccordionContainer,
  FilterCollapsibleList,
  FilterContainer,
  FilterFooter,
  FilterHeader,
} from '~/components/Filter';
import type { FilterItem, FiltersState } from '../lib';

interface FilterAccordionProps {
  clearFilter: () => void;
  filters: FiltersState;
  filterCounts?: AuditLogsList__FilterCount;
  toggleAccordion: (key: 'recordTypes' | 'userIds') => void;
  updateFilter: (key: 'recordTypes' | 'userIds', newItems: FilterItem[]) => void;
}

export const FilterAccordion = ({
  clearFilter,
  filters,
  filterCounts,
  toggleAccordion,
  updateFilter,
}: FilterAccordionProps) => {
  return (
    <FilterContainer>
      <FilterHeader />
      <FilterAccordionContainer>
        <>
          <FilterCollapsibleList
            Icon={People}
            expanded={filters.userIds.expanded}
            items={filters.userIds.items.map((item) => {
              const matched = filterCounts?.userIds.find((c) => c.filter === item.value);
              return { ...item, count: matched?.count || 0 };
            })}
            label="Users"
            loading={!filterCounts}
            onClick={(_, newItems) => updateFilter('userIds', newItems)}
            onExpand={() => toggleAccordion('userIds')}
          />
          <FilterCollapsibleList
            Icon={Category}
            expanded={filters.recordTypes.expanded}
            items={filters.recordTypes.items.map((item) => {
              const matched = filterCounts?.recordTypes.find((c) => c.filter === item.value);
              return { ...item, count: matched?.count || 0 };
            })}
            label="Record Types"
            loading={!filterCounts}
            onClick={(_, newItems) => updateFilter('recordTypes', newItems)}
            onExpand={() => toggleAccordion('recordTypes')}
          />
        </>
      </FilterAccordionContainer>
      <FilterFooter handleClear={clearFilter} />
    </FilterContainer>
  );
};

import { Event, MoreVert, MovieCreation } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MuiMenu,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDeleteEventType } from '~/api/event-types/delete';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type NetworkEventTypes__EventType as EventType } from '../../queries/list.generated';

export interface MenuProps {
  eventType: EventType;
}

export const Menu = ({ eventType }: MenuProps) => {
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<EventType>();
  const eventTypeActionProps = moreTableActionProps(eventType);

  const link = useLink();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [deleteEventType] = useDeleteEventType();

  return (
    <>
      <Tooltip title="Actions" arrow>
        <IconButton
          color="secondary"
          aria-label="actions"
          onClick={eventTypeActionProps.onClick}
          size="small"
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <MuiMenu {...moreMenuProps}>
        <MenuItem
          component={Link}
          to={link(`/event-types/${moreMenuProps.context?.id}/events`)}
          state={true}
        >
          <ListItemIcon>
            <Event />
          </ListItemIcon>
          <ListItemText>Events</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to={link(`/event-types/${moreMenuProps.context?.id}/shows`)}
          state={true}
        >
          <ListItemIcon>
            <MovieCreation />
          </ListItemIcon>
          <ListItemText>Shows</ListItemText>
        </MenuItem>
        <Tooltip
          title={
            moreMenuProps.context?.canDestroy.value
              ? 'Delete Event Type'
              : moreMenuProps.context?.canDestroy.reasons?.fullMessages.join(', ') ||
                'Cannot delete Event Type'
          }
        >
          <span>
            <Divider />
            <MoreDeleteAction
              {...moreActionProps}
              disabled={!moreMenuProps.context?.canDestroy.value}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined, 'Data missing from context');
                if (!(await confirmDelete())) return;
                await deleteEventType({
                  variables: {
                    id: moreMenuProps.context.id,
                  },
                });
              }}
            />
          </span>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting this Event Type will permanently remove it from the system, along with{' '}
            {eventType.eventCount} associated events. <b>This operation may not be undone.</b>
          </span>
        }
        title="Delete Event Type"
      />
    </>
  );
};
